.CenterBtn {
    border: none !important;
    background-color: #0f4c82!important;
  }
  
  .CenterBtn:hover {
    background-color: rgba(15, 76, 130, .9)!important;
  }
  
  .CenterBtn[disabled] {
    color: #fff;
    background-color: rgba(15,76,130,.6)!important;
  }
  
  .CenterBtn[disabled]:hover {
    color: #fff;
    background-color: rgba(15,76,130,.6)!important;
  }
  
  .btnWhite {
    height: 45px;
    width: 130px;
    border-radius: 100px;
    font-size: 1rem;
    background-color: white !important;
    border-color: #bebebe !important;
    padding-left: 30px;
    padding-right: 30px;
    color: #bebebe !important;
  }
  .btnsmall {
    height: 35px;
    // width: 100%;
    border-radius: 100px;
    font-size: 0.9rem;
    background-color: #0f4c82 !important;
    border-color: #0f4c82 !important;
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .btnsmall[disabled] {
    color: white;
    background-color: rgba(15, 76, 130, 0.6) !important;
    border-style: none;
  }
  
  .btnsmall[disabled]:hover {
    color: white;
    background-color: rgba(15, 76, 130, 0.6) !important;
    border-style: none;
  }
  
  .btmsmallWhite {
    height: 35px;
    // width: 100%;
    border-radius: 100px;
    font-size: 0.9rem;
    background-color: #FFFFFF !important;
    border-color: #bebebe !important;
    padding-left: 30px;
    padding-right: 30px;
    color: #bebebe !important;
  }
  
  .disabled {
    pointer-events: none !important;
  }
  