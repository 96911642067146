.modalTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.modalFooter {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  .cancelBtn {
    height: 100%;
    width: 48%;
    border-radius: 8px;
    padding: 0 8px;
    border: 1px solid #bfbebe; // เพิ่ม border เพื่อสร้างกรอบ
  }
  .confirmBtn {
    height: 100%;
    width: 48%;
    background: #1c87f2;
    color: #ffffff;
    border-radius: 8px;
  }
  .confirmBtn:hover {
    background-color: #1c87f2;
  }
}
.modalContent {
  display: flex;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .uploadContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;

    :global .ant-upload.ant-upload-select {
      width: 100% !important;
      height: 100% !important;
      border-radius: 10px;
      border: 1px dashed rgba(34, 34, 34, 0.2) !important;
      background: #fff;
    }
  }

  .ImportContent{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 100%;
  }
}

.modalContentExcel {
  padding: 15px 0;
}


.ImportContent:nth-child(2){
  color: red($color: #000000);
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover {
  background-color: transparent !important;
  cursor: default !important;
}

/* Additional styles to ensure hover effect is disabled */
.ant-upload-list-item {
  transition: none !important;
}