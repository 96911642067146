@mixin mobile {
    @media (max-width: 600px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 601px) and (max-width: 960px) {
        @content;
    }
}

.cardContainer {
    height: 100%;
    border-radius: 16px;
    background-color: #ffffff;
    padding-top: 22px;
}


.labelSelect {
    color: #212B36;
    font-size: 14px;

    @include mobile {
        font-size: 12px;
    }

    margin-bottom: 8px;
}

.inputField {
    width: 100%;
    margin-bottom: 16px;
}

.cardElementContainer {
    border: 1px solid #ddd;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: white;
    min-height: 40px;
    display: flex;
    align-items: center;

    &:hover {
        border-color: #919EAB;
    }

    &:focus-within {
        border-color: #212B36;
    }
}

.errorMessage {
    color: #FF5630;
    font-size: 12px;
    margin-top: 4px;
}

.submitButton {
    margin-top: 24px;
    text-transform: none;
    font-weight: 600;
}