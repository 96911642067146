.modalOveride {

 

  >div:not(:first-child):not(:last-child) {
    height: 100% !important;
    // max-height: 700px !important;
    max-height: calc(100vh - 100px) !important;
  }

 
}

.modalOveride {
  // height: 500px;
  > div {
    border-radius: 10px !important;
    > div> div:last-child {
      display: none;
    }
  }
  > div> div > button {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .modalOveride {
    min-width: 95% !important;
  }
}

@media only screen and (min-width: 769px) {
  .modalOveride {
    min-width: 700px !important;
  }
}

.closeBtn {
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.bottonBtn {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  width: 100%;
  height: 52px;
  border-radius: 3px;
  background-color: #3875F6;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}

.bottonBtnUpload {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  width: 100%;
  height: 52px;
  border-radius: 100px;
  background-color: #3875F6;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}

.boxCreate {
  margin: 15px;
  margin-top: 10px;
}

.boxCreateInput {
  width: 100%;
  font-size: 1.25rem;
  border-radius: 5px;
  border-width: 1px;
  padding: 10px;
  border-color: transparent;
  background-color: #f0f0f0;
}

.uploadBtn {
  /* padding: 12px 23px 12px 23px;
  text-align: center; */
  font-size: 1.875rem;
  width: 42px;
  height: 42px;
  border-radius: 5px;
  background-color: #E8F4FF;
  // color: #ffffff;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.textOverflowListFileText_upload {
  // margin: auto;
  font-size: 0.9rem;
  color: #36393A;
  white-space: nowrap;
  overflow: hidden;
  width: 95%;

  // font-size: 1rem;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.textUpload {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 5px;
  color: #36393A;
}

.listUploadFileContainer {
  margin-bottom: 30px;
  margin-top: 30px;
  max-height: calc(100vh - 380px);
  overflow-y: auto;
}

.listUploadFile {
  display: flex;
  padding: 5px 10px 5px 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #E7EDF0;
  border-radius: 5px;

}

.verticalCenter {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sizeUpload {
  font-size: 0.75rem;
  color: #A0B1BD;
}

.closeBtnItem {
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.boxbtnUploading {

  // font-size: 1rem;
  >div {
    >div {
      margin-right: unset !important;
      padding-right: unset !important;
    }

    >span {
      position: absolute;
      right: 297px;
      top: 20px;
      font-size: 1rem;
      font-weight: bold;
      color: #3875F6;
    }
  }
}

.boxbtnUploadingMore50 {

  // font-size: 1rem;
  >div {
    >div {
      margin-right: unset !important;
      padding-right: unset !important;
    }

    >span {
      position: absolute;
      right: 297px;
      top: 20px;
      font-size: 1rem;
      font-weight: bold;
      color: white;
    }
  }
}

.progressNormal {
  // font-size: 1rem;
  width: 95%;

  >div {
    >span {
      color: #3875F6 !important;
    }
  }
}

.progressWarning {
  // font-size: 1rem;
  width: 95%;

  >div {
    >span {
      color: #ff9c00 !important;
    }
  }
}

.progressError {
  // font-size: 1rem;
  width: 95%;

  >div {
    >span {
      color: red !important;
    }
  }
}

.overideBlockUI {
  z-index: 9999;

  >div {
    >div {
      position: fixed;
      z-index: 1010;

      >div:last-child {
        z-index: 11;

        >div {
          >div {
            >span {
              color: #3875F6 !important;
            }
          }
        }
      }
    }
  }
}


.listUploadFile::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.listUploadFile::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.listUploadFile:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}