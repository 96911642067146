.controlTableContainer {
  border: #bebebe solid 1px;
  background: #fff;

  .controlTableHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    .controlTableButton {
      height: 50px;
      width: 100%;
      margin-bottom: 5px;
      background: #f8f8f8;
      font-size: medium;
      border: 0.5px solid #bebebe;
      cursor: pointer;
    }
  }

  .controlTableBody {
    .controlTableRow {
      display: flex;
      min-height: 50px;

      .insideTableColFocus {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: #1c87f2 solid 1px;
        border-radius: 6px;
        background: #fff;
      }
      .insideTableCol {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: #bebebe solid 1px;
        z-index: 1;
        border-radius: 6px;
        background: #fff;
      }
      .insideTableCol:hover {
        border: #1c87f2 solid 1.5px;
      }

      .controlTableCol {
        padding: 10px;
        border: #bebebe solid 1px;
        flex: 1 0 auto;
      }
      .controlTableCol:hover {
        padding: 10px;
        border: #1c87f2 solid 1.5px;
      }
      .controlTableColDragOver {
        padding: 10px;
        flex: 1 0 auto;
        background: rgba(28, 135, 242, 0.5) !important;
      }
      .controlTableColFocus {
        padding: 10px;
        border: #1c87f2 solid 1px;
        background: #e8f4ff !important;
      }
    }
  }
}
