.card {
    width: 150px;
    height: 64px;
    cursor: pointer;
  }
  
  .cardContent {
    display: inline-flex;
    text-align: center;
    align-items: center;
    gap: 12px;
  }
  
  .titleContainer {
    font-size: 15px;
    font-weight: 400;
  }

  .activeCard {
    border-color: #1875F0 ; 
  }