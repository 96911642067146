.searchInput {
  border: none;
}
.rowTr {
  cursor: pointer;
}
.rowTrSelected {
  cursor: pointer;
  background: #e7edf2;
}

.messageNotify {
  padding-left: 15px;
  font-weight: 600;
}
.descriptionNotify {
  padding-left: 15px;
  font-size: 12px;
}
.iconNotify {
  display: flex;
  align-items: center;
  height: 54px;
  width: 54px;
  margin-right: 10px;
  margin-left: -10px;
}
.iconSuccessNotify {
  font-size: 35px;
}
