.cardGroupHeader {
  font-size: 1em;
  font-weight: 500;
  color: #36393A;
}

.cardGroup {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-gap: 15px 7px;
  padding-top: 25px;
}
.boxFolder {
  width: 250px;
  height: 100px;
  background: rgb(255, 255, 255);
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
  border: 1px solid #E7EDF0;
  padding: 20px 15px;
  border-radius: 10px;
}

.textOverflowListFileText {
  // margin: auto;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: 0.9rem;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
  }
}

.iconMenu {
  font-size: 2rem;
  color: rgb(124, 124, 124);
}

@media only screen and (min-width: 500px) {


  .boxFolder{
    width: 250px;
  }
  .cardGroup{
    grid-template-columns: repeat(auto-fill, 250px);
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 499px) {
  .boxFolder{
    width: 100%;
  }
  .cardGroup{
    grid-template-columns: repeat(auto-fill, 100%);
    padding-left: 5px;
    padding-right: 5px;
  }
}