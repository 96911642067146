.modalTitle {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
}
.modalFooter {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    .cancelBtn {
        height: 100%;
        width: 48%;
        border-radius: 8px;
        padding: 0 8px;
    }
    .confirmBtn {
        height: 100%;
        width: 48%;
        background: #D92D20;
        color: #FFFFFF;
        border-radius: 8px;
    }
    .confirmBtn:hover {
        background-color: #D92D20;
    }
}
.modalContent {
    text-align: center;
    margin-bottom: 20px;
}