.iconContainerStyle {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.editIconStyle {
    cursor: pointer;
}

.deleteIconStyle {
    cursor: pointer;
}

.tableBody thead tr th,td {
    border: none;
    background: none !important;

    &::before {
        content: none !important;
    }
}