@mixin tabTemplate($isActive: false) {
  border-radius: 5px;
  padding-right: 16px;
  padding-left: 16px;

  @if $isActive {
    background-color: rgba(28, 135, 242, 0.15);
  } @else {
    color: #ababab;
  }
}
.editStoryContainer {
  .storyNameHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: solid 1px #eaeaea;
    padding: 15px 0;

    .storyName {
      font-size: 18px;
      font-weight: 600;
      margin-right: 10px;

      p {
        margin: 0;
      }

      .inputStoryName {
        border: none;
      }
      .inputStoryName:focus {
        outline: none;
      }
    }
    .editBtn {
      margin-top: 5px;
      cursor: pointer;
    }
  }

  .storyContent {
    .storyTab {
        margin-top: 15px;
    }
    .storyTabPane {
    }
  }
}
.customTabBar {
  background-color: #fffefe;
  // padding-top: 10px;
}

.tabActive {
  @include tabTemplate(true);
}
.tab {
  @include tabTemplate(false);
}

.tabBarContainer {
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  display: inline-flex;
}
