.planDetailsList {
    width: 100%;

    :global {
        .ant-list-item-meta-title {
            text-transform: capitalize;
            font-weight: 600;
        }

        .ant-list-item-meta-description {
            color: rgba(0, 0, 0, 0.65);
        }
    }
}

.contentWrapper {
    width: 100%;
    padding: 16px 0;
}

.subscriptionCard {
    background: linear-gradient(to right, #ffffff, #f8faff);
    border: 1px solid rgba(67, 97, 238, 0.1);
    border-radius: 20px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.03);
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 8px 32px rgba(0, 0, 0, 0.05);
    }
}

.balanceRow {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
}

.balanceLabel {
    font-size: 18px;
    color: #1e293b;
    font-weight: 500;
}

.infoIcon {
    color: #64748b;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        color: #4361ee;
        transform: scale(1.1);
    }
}

.balanceAmount {
    font-size: 36px;
    font-weight: 700;
    background: linear-gradient(135deg, #4361ee, #3b82f6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
    margin-top: 8px;
}

.buttonGroup {
    margin-top: 24px;
    gap: 16px;
}

.primaryButton {
    height: 44px;
    padding: 0 28px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 15px;
    transition: all 0.3s ease;
    
    &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(67, 97, 238, 0.2);
    }
}

.secondaryButton {
    height: 44px;
    padding: 0 28px;
    border-radius: 12px;
    font-weight: 600;
    font-size: 15px;
    background: #f1f5f9;
    color: #475569;
    transition: all 0.3s ease;

    &:hover {
        background: #e2e8f0;
        transform: translateY(-1px);
    }
}

.featuresGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 20px;
    margin-top: 32px;
}

.featureCard {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 16px;
    padding: 24px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &:hover {
        border-color: #4361ee;
        transform: translateY(-2px);
        box-shadow: 0 8px 24px rgba(67, 97, 238, 0.1);

        .featureValue {
            color: #4361ee;
        }
    }
}

.featureLabel {
    font-size: 14px;
    color: #64748b;
    font-weight: 500;
    margin-bottom: 8px;
}

.featureValue {
    font-size: 24px;
    color: #1e293b;
    font-weight: 700;
    transition: color 0.3s ease;
}

.menuGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    margin-top: 32px;
}

.menuCard {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 16px;
    transition: all 0.3s ease;
    overflow: hidden;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);

        .menuIcon {
            transform: scale(1.1);
        }
    }
}

.menuContent {
    padding: 24px;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.menuIcon {
    padding: 12px;
    border-radius: 12px;
    transition: all 0.3s ease;
    background: #f8faff;
}

.menuInfo {
    flex: 1;

    h3 {
        font-size: 18px;
        font-weight: 600;
        color: #1e293b;
        margin-bottom: 8px;
    }

    p {
        font-size: 14px;
        color: #64748b;
        margin: 0;
    }
}

.logoPromptX {
    width: 32px;
    height: 32px;
    transition: all 0.3s ease;

    &:hover {
        transform: rotate(15deg);
    }
}

// Responsive styles
@media (max-width: 1024px) {
    .featuresGrid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 16px;
    }

    .menuGrid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media (max-width: 768px) {
    .contentWrapper {
        padding: 12px 0;
    }

    .balanceAmount {
        font-size: 32px;
    }

    .menuContent {
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .featuresGrid {
        grid-template-columns: 1fr;
    }

    .menuGrid {
        grid-template-columns: 1fr;
    }

    .balanceLabel {
        font-size: 16px;
    }

    .balanceAmount {
        font-size: 28px;
    }
    
    .buttonGroup {
        flex-direction: column;
        width: 100%;

        button {
            width: 100%;
        }
    }
}
