* {
    box-sizing: border-box;
}

.mainHeader{
    height: 84px;
    color: #121927;
    font-size: 20px;
    font-weight: 500;
    line-height: 42px;
    text-align: left;
    padding: 20px 50px;
    background: #fff;
    border-bottom: 1px solid #DCDCDC;
    display: flex;
    justify-content: space-between;
}

.tableData{
    padding: 20px;
}
