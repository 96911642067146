.modalTitle {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2937;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
}

.titleButtons {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 32px; // Space for the close button

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s;
    
    &:hover {
      transform: scale(1.05);
    }
  }
}

.modalContainer {
  height: calc(100vh - 120px);
  width: 100%;
  background-color: white;
  
  @media (min-width: 768px) {
    height: 80vh;
  }
}

.panelContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.codeContainer {
  flex: 1;
  overflow: hidden;
}

.codeScroll {
  height: 100%;
  overflow: auto;
}

.commentBoxWrapper {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: -40px;
  background: linear-gradient(to top, white, rgba(255,255,255,0.95), transparent);
}

.commentBoxInner {
  width: 100%;
  max-width: 48rem;
  margin: 0 auto;
}

.resizeHandleMobile {
  height: 0.5rem;
  width: 100%;
  background-color: #e5e7eb;
  cursor: row-resize;
  touch-action: pan-y;
  transition: background-color 0.2s;

  &:hover {
    background-color: #d1d5db;
  }

  &:active {
    background-color: #60a5fa;
  }
}

.resizeHandleBar {
  width: 3rem;
  height: 0.25rem;
  margin: 0.125rem auto;
  background-color: #9ca3af;
  border-radius: 9999px;
}

.resizeHandleDesktop {
  width: 0.25rem;
  height: 100%;
  background-color: #e5e7eb;
  cursor: col-resize;
  transition: background-color 0.2s;

  @media (min-width: 768px) {
    width: 0.5rem;
  }

  &:hover {
    background-color: #d1d5db;
  }

  &:active {
    background-color: #60a5fa;
  }
}

.previewContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 0.5rem;
}

.previewFrame {
  height: 100%;
  width: 100%;
  background-color: #f9fafb;
  border-radius: 0.5rem;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.allTab {
  position: relative;
  height: calc(100vh - 120px);
}

.tab {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  margin-bottom: 0.5rem;
  top: -40px;
}

.chatMainContainer {
  position: relative;
  min-height: 70vh;
}

.panelChat {
  overflow: auto !important;
  margin-top: 50px;
  position: relative;
}

.panelPreview {
  overflow: auto !important;
  padding-top: 50px;
  padding-left: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
