.modalContentWrapper {
  .modalTitle {
    font-size: 18px;
    font-weight: 500;
  }
  .modalContent {
    .versionContainer {
        margin-bottom: 15px;
      .textTopic {
        display: flex;
        gap: 5px;
        .text {
          font-size: 15px;
        }
        .textImportant {
          font-size: 18px;
          color: #d92d20;
        }
      }
      .inputVersionName {
      }
    }

    .descContainer {
        margin-bottom: 15px;
      .textTopic {
        display: flex;
        gap: 5px;
        .text {
          font-size: 15px;
        }
        .textImportant {
          font-size: 18px;
          color: #d92d20;
        }
      }
      .inputDescription {
      }
    }
  }
}
.modalFooter {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  .cancelBtn {
    height: 100%;
    width: 48%;
    border-radius: 8px;
    padding: 0 8px;
  }
  .confirmBtn {
    height: 100%;
    width: 48%;
    background: #1C87F2;
    color: #ffffff;
    border-radius: 8px;
  }
  .confirmBtn:hover {
    background-color: #1C87F2;
  }
}
