.storyCardCreateContainer {
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.16);
  border: none;
  transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  cursor: pointer;
}
.storyCardItemContainer {
  width: 100%;
  height: 240px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 18px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.16);
  border: none;
  transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  cursor: pointer;

  .imgWrapper {
    width: 100%;
    height: 120px;
    border-radius: 18px;
    
    .img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-radius: 18px;
    }
  }

  .detailWrapper {
    width: 100%;
    .title {
      text-align: start;
    }
    .detail {
      display: flex;
      justify-content: space-between;

      .detailLeft {
        display: flex;
        flex-direction: row;

        .imgWrapper {
          width: 35px;
          height: 35px;

          .profileImg {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
          }
        }
        .textWrapper {
          margin-left: 8px;
          display: flex;
          flex-direction: column;

          .textEmail {
            font-size: 12px;
            margin: 0;
          }
          .textUploadDate {
            font-size: 10px;
            margin: 0;
            color: var(--text-color-gray);
          }
        }
      }
      .detailRight {
        .expandWrapper {
          width: 35px;
          height: 35px;
          background: var(--bg-color-blue);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 12px;
        }
      }
    }
  }
}
