.container {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // height: 100vh;
    background-color: #f8f8f8;
    padding: 15px;
    height: 100vh;
    box-sizing: border-box;
}

.mainContent {
    flex: 1;
    // padding: 20px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    background: #fff;
    border-radius: 15px;
    padding: 10px 20px 50px 20px ;
    // overflow: auto;
    gap: 30px;
}

.searchContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .searchBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        box-sizing: border-box;
        background: #F8F8F8;
        border-radius: 100px;
        border: none;
        padding: 10px 20px;
        color: #6F6F6F;
        min-width: 400px;

        .searchInput{
            color: #6F6F6F;
            box-sizing: border-box;
            width: 90%;
            background: #F8F8F8;
            border: none;
        }

        .searchInput:focus{
            // border: none;
            background-color: #F8F8F8;
            height: 30px;
            outline: none;
        }
    }
}

.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 2px;
}


.trH{
    background-color: rgb(248, 248, 248);
    text-align: left;
    border-radius: 8px;
}

.th{
    padding: 20px;
    // border-bottom: 1px solid rgb(233, 233, 233);   
    font-weight: 400;
    font-size: 12px;
    color: #393939;

}

.trR{
    text-align: left;
}

.td{
    padding: 20px !important;
    border-bottom: 1px solid rgb(233, 233, 233);
    border-top: none !important;
    font-weight: 400;
    font-size: 16px;
    color: #8D8D8D;
}

td:nth-child(1) {  
    color: #393939 !important;
}

.headerContainer{
    padding: 20px;
    border-bottom: 1px solid #E1E1E3;
    display: flex;
    align-items: center;
    gap: 15px;
    background: #fff;

    .headerText{
        font-size: 18px;
        font-weight: 500;
    }
}

.searchText{
    font-size: 22px;
    font-weight: 600;
}
