.chooseTempContainer {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;


  .chooseTempItemWrapper {
    padding-bottom: 16px;
    border-top: 2px solid #f8f8f8;
    .titleWrapper {
      height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
    }
    .itemWrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 15px;
      overflow: hidden;

      .cardItem {
        height: 200px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        overflow: hidden;

        .imgWrapper {
          width: 100%;
          height: 100%;
          overflow: hidden;
          object-fit: contain;
          background: #C3D6F0;
        }
        .contentWrapper {
          background-color: #f8f8f8;
          color: #000000;
          height: 50px;
          display: flex;
          align-items: center;
          padding: 5px 15px;
          margin-top: -5px;
          font-size: 14px;
          border-radius: 0 0 8px 8px;
        }
        .radioWrapper{
            display: flex;
            justify-content: center;
            margin-top: 10px;
        }
      }
    }
  }
}
