.storyContainer {
  height: 100%;
}

.storyWrapper {
  margin-bottom: 15px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.storyEmptyWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  color: black;
  font-size: 14px;
  font-weight: 400;
}

.cardGrid {
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}
