.boxMenuTabActive {
  background-color: #f6f5f5;
}

.countFileHeaderDes {
  padding-left: 10px;
  font-size: 0.6875rem;
  font-weight: 500;
  margin: auto;
  margin-left: 0px;
  color: #A0B1BD;
}

.boxProgressBar {
  // font-size: 1rem;
  > div {
    > div {
      margin-right: unset !important;
      padding-right: unset !important;
      > div {
        background-color: #d4d4d4;
      }
      > span {
        display: none!important;
      }
    }
    > span {
      position: absolute;
      right: 297px;
      top: 20px;
      font-size: 1rem;
      font-weight: bold;
      color: #0f4c82;
      display: none!important;
    }
  }
}

.boxMenuTab:hover {
  background-color: #f6f5f5;
}

.boxMenuTab {
  width: 100%;
  display: flex;
  // align-items: center;
  // padding-right: 10px;
  // padding-left: 5px;
  padding: 15px 10px;
  // border-radius: 5px;
  cursor: pointer;
  // padding-left: 15px;
  // padding-top: 15px;
  // padding-bottom: 15px;
  border-bottom: 1px solid #E7EDF0;

  > div.iconImg {
    width: 39px;
    height: 39px;
    // margin: 4px;
    align-self: CENTER;
    flex: none;
    background-repeat: no-repeat;
    background-size: contain;
    background: right;
  }

  > div.fileDesc {
    margin-left: 8px;
    margin-right: 8px;
    width: 100%;
    overflow: hidden;

    > div:first-child {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1rem;
      font-weight: 400;
      color: #36393A;
    }

    > div:last-child {
      font-size: 0.75rem;
      color: #A0B1BD
    }
  }

  > div.fileSize {
    font-size: 1rem;
    font-weight: 400;
  }
}

.iconImg {
  width: 47px;
  height: 47px;
  background-repeat: no-repeat;
  background-size: contain;
}

.boxFileMain{
  padding-right: 25px;
  overflow: auto;
  height: calc(100vh - 192px);
}

.boxFileMenu{
  overflow: auto;
  height: calc(100vh - 114px);
  padding: 25px 15px;
}

.fileNumber{
  color: #36393A;
}

.fileUnit{
  margin-left: 5px;
  font-size: 0.75rem;
  color: #A0B1BD;
}