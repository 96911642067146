.verticalCenter{
  display: flex;
  align-items: center;
}

.fileCardBox {
    width: 100%;
    height: 80px;
    background: rgb(255, 255, 255);
    border: 1px solid #E7EDF0;
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    min-width: 320px;
}

.textOverflowListFileText {
    color: #36393A;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

.linkHead{
    font-size: 1rem;
    color: #36393A;
}

.link{
    font-size: 0.9rem;
    color: #A0B1BD;
}