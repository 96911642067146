.btnLogin {
  border-radius: 5px;
  margin-top: 10px;
  background-color: #868686;
  border-color: #868686;
  float: right;
}
.btnLogin:hover, .btnLogin:focus {
  color: #fff;
  background-color: #9c9c9c;
  border-color: #9c9c9c;
}