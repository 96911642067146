.container {
    width: 100%;
    height: 100vh;
    background: #F2F2F2;
    flex-direction: column;
    // justify-content: flex-start;
    // align-items: flex-start;
    gap: 40px;
    display: flex;
    overflow: hidden;
}

.content {
    flex: 1 1 0;
    height: 100%;
    padding-top: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
}

.header {
    width: 100%;
    height: 122px;
    position: relative;
}

.imageContainer {
    width: 50px;
    height: 48px;
    left: 57px;
    top: 0px;
    position: absolute;
}

.image {
    width: 50px;
    height: 48px;
    left: 0px;
    top: 0px;
    position: absolute;
    background: #1976D3;
    border-radius: 50%;
}

.letter {
    width: 15px;
    left: 17px;
    top: 10px;
    position: absolute;
    color: white;
    font-size: 21px;
    font-family: Arial;
    font-weight: 700;
    word-wrap: break-word;
}

.title {
    width: 162px;
    left: 0px;
    // top: 64px;
    // position: absolute;
    text-align: center;
    color: #272727;
    font-family: Arial;
    font-size: 14px;
    font-weight: 700;
    // line-height: 18px;
    word-wrap: break-word;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 5px;
    padding-right: 5px;
}

.createdBy, .version {
    display: flex;
    justify-Content: center;
    line-Height: 24px;
    color: black;
    font-size: 10px;
    font-weight: 400;
    font-family: Arial;
    word-wrap: break-word;

}

.nameCreate{
    width: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.textMenu {
    font-family: 'Arial';
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    word-wrap: break-word;
    cursor: pointer;
}

.menuItemActiveContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuItemContainer {
    color: #ABABAB;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menuItemListContent {
    display: flex;
    align-items: center;
    overflow: hidden;
    color: #ABABAB;
}

.menuItemContent {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.menuItemLabel {
    margin-left: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.layoutStyle {
    border-radius: 8;
    overflow: hidden;
    width: calc(50% - 8px);
    max-width: calc(50% - 8px);
}

.siderStyle {
    text-align: center;
    line-height: 120px;
    color: #fff;
    background-color: #1677ff;
}

.headerStyle {
    text-align: center;
    color: #fff;
    height: 64;
    padding: 48;
    line-height: 64px;
    background-color: #4096ff;
}

.contentStyle {
    text-align: center;
    min-height: 120;
    line-height: 120px;
    color: #fff;
    background-color: #0958d9;
}

.customScrollbar {
    padding: 20px 30px;
    background-color: white;
    height: 100%;
    overflow-y: auto;
}

.customScrollbar::-webkit-scrollbar {
    display: none;
}

.titleContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 64px;
    line-height: 45px;
}