.active {
    color: #1875F0;
    font-family: 'Arial';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word;
    cursor: pointer;
}

.breadcrumbItem {
    color: #ABABAB;
    font-family: 'Arial';
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    word-wrap: break-word;
    cursor: pointer;
}

.angleLeftIcon {
    width: 100%;
    height: 100%;
    position: relative ;
}