.modalContainer {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    display: inline-flex;
    margin-bottom: 10px;
}

  
.titleText {
    width: 100%;
    align-self: stretch;
    text-align: center;
    color: #101828;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    // line-height: 20px;
    word-wrap: break-word;
    
    p{
        margin-top: 0;
        margin-bottom: 7px;
    }

    .fileName{
        // color: rgb(255, 62, 62);
    }
}
  
.descriptionText {
    width: 100%;
    align-self: stretch;
    flex: 1 1 0;
    text-align: center;
    color: #475467;
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    word-wrap: break-word;
}

.footerModalWarning{
    display: flex;
    gap: 15px;
    justify-content: center;
}