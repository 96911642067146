/* @tailwind base; */
@tailwind components;
@tailwind utilities;
@layer utilities {
  .rotate-180 {
    transform: rotate(180deg);
  }
  .scrollbar {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
      transition: background 0.2s ease;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .scrollbar-hide {
    &::-webkit-scrollbar {
      width: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
