.modalTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.modalFooter {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  .cancelBtn {
    height: 100%;
    width: 48%;
    border-radius: 8px;
    padding: 0 8px;
  }
  .confirmBtn {
    height: 100%;
    width: 48%;
    background: #1c87f2;
    color: #ffffff;
    border-radius: 8px;
  }
  .confirmBtn:hover {
    background-color: #1c87f2;
  }
}

.inputNameContainer {
  margin-top: 25px;
  display: flex;
  // font-Weight: bolder;
  // align-items: center;
}

.uploadIconContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 10px;
}

.uploadIconContainer label{
  width: 100%;
  text-align: left;
}