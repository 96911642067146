.editStEntContainer {
  margin-top: 10px;

  .entConfig {
    margin-bottom: 30px;

    .entConfigTitle {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .entConfigInput {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 12px;

      .entInputSort {
        height: 38px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-inline-start: 10px;
        border: solid 1px #d9d9d9;
        width: 350px;
        border-radius: 6px;

        .sortTitle {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #d9d9d9;
        }
        .entInputSelect {
          display: flex;
          justify-content: flex-start;
        }
      }
      .createBoBtn {
        height: 38px;
        font-size: 12px;
      }
    }
  }
  .entSelected {
    .entSelectedTitle {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .entSelectedList {
      .entSelectedItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #e9e9e9;
        padding: 6px 0;

        .entAction {
          height: 100%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          gap: 14px;

          .genPageBtn {
            color: #ababab;
          }
          .genPageBtn:hover {
            color: #1c87f2;
          }
          .genMFBtn {
            color: #ababab;
          }
          .genMFBtn:hover {
            color: #1c87f2;
          }
          .actionIconWrapper {
            height: 32px;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 6px;
            border: solid 1px #e9e9e9;

            .actionIcon {
              fill: #ababab;
            }
          }
          .actionIconWrapper:hover {
            border-color: #1c87f2;

            .actionIcon {
              fill: #1c87f2;
            }
          }
        }
      }
    }
  }
}
