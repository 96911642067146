.cardGroupHeader {
  font-size: 1rem;
  font-weight: 500;
  color: #36393A;
  margin-bottom: 5px;
}

.cardGroup {
  display: grid;
  // justify-content: space-between;
  // grid-template-columns: repeat(auto-fill, 363px);
  grid-gap: 10px;
  padding-top: 15px;
  padding-left: 6px;
    padding-right: 8px;
}


.fileTypeBox_audio {
  color: white;
  // padding: 20px;
  // background-color: #ffead4 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_text {
  color: white;
  // padding: 20px;
  // background-color: #acb2ff !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_video {
  color: white;
  // padding: 20px;
  // background-color: #d3e3f9 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_pic {
  color: white;
  // padding: 20px;
  background-color: #fde9cc !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_other {
  color: white;
  // padding: 20px;
  // background-color: #f5e6fe !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_bin {
  // color: white;
  // padding: 20px;
  background-color: #fd6b6b !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_audio_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #ffead440 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_text_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #acb2ff40 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_video_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #c2dbfd40 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_pic_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #c2dbfd !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_other_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #f5e6fe40 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_bin_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #fd6b6b40 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_doc_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #d6d5fe40 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_doc {
  color: white;
  // padding: 20px;
  // background-color: #d6d5fe !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_xls_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #c0e7e040 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_xls {
  color: white;
  // padding: 20px;
  // background-color: #c0e7e0 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_ppt_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #f4d1d140 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_ppt {
  color: white;
  // padding: 20px;
  // background-color: #f4d1d1 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_svg_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #225cc340 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_svg {
  color: white;
  // padding: 20px;
  // background-color: #225cc3 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_pdf_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #799bb940 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_pdf {
  color: white;
  // padding: 20px;
  // background-color: #799bb9 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_ai_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #ffecd340 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_ai {
  color: white;
  // padding: 20px;
  // background-color: #ffecd3 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_xd_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #ffd6f640 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_xd {
  color: white;
  // padding: 20px;
  // background-color: #ffd6f6 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.fileTypeBox_zip_activeMove {
  color: white;
  // padding: 20px;
  // background-color: #d7f3ed40 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}
.fileTypeBox_zip {
  color: white;
  // padding: 20px;
  // background-color: #d7f3ed !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.addIconColor {
  // background-color: #ffffff33;
  color: white;
  // padding: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
  border-radius: 5px;
}
.verticalCenter {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fileCardBox{
  width: 100%;
  height: 80px;
  background: rgb(255, 255, 255);
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
  border: 1px solid #E7EDF0;
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  min-width: 320px;
}
.textOverflowListFileText {
  // margin: auto;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}






.fileTypeBox_audio_color {
  color: white;
  // background-color: #ffead4 !important;
}

.fileTypeBox_text_color {
  color: white;
  // background-color: #acb2ff !important;
}

.fileTypeBox_video_color {
  color: white;
  // background-color: #d3e3f9 !important;
}
.fileTypeBox_pic_color {
  color: white;
  background-color: #fde9cc !important;
}
.fileTypeBox_other_color {
  color: white;
  // background-color: #f5e6fe !important;
}
.fileTypeBox_bin_color {
  // color: white;
  background-color: #fd6b6b !important;
}

.fileTypeBox_audio_activeMove_color {
  color: white;
  // background-color: #ffead440 !important;
}

.fileTypeBox_text_activeMove_color {
  color: white;
  // background-color: #acb2ff40 !important;
}

.fileTypeBox_video_activeMove_color {
  color: white;
  // background-color: #c2dbfd40 !important;
}
.fileTypeBox_pic_activeMove_color {
  color: white;
  // background-color: #c2dbfd !important;
}
.fileTypeBox_other_activeMove_color {
  color: white;
  // background-color: #f5e6fe40 !important;
}
.fileTypeBox_bin_activeMove_color {
  color: white;
  // background-color: #fd6b6b40 !important;
}

.fileTypeBox_doc_activeMove_color {
  color: white;
  // background-color: #d6d5fe40 !important;
}
.fileTypeBox_doc_color {
  color: white;
  // background-color: #d6d5fe !important;
}

.fileTypeBox_xls_activeMove_color {
  color: white;
  // background-color: #c0e7e040 !important;
}
.fileTypeBox_xls_color {
  color: white;
  // background-color: #c0e7e0 !important;
}

.fileTypeBox_ppt_activeMove_color {
  color: white;
  // background-color: #f4d1d140 !important;
}
.fileTypeBox_ppt_color {
  color: white;
  // background-color: #f4d1d1 !important;
}

.fileTypeBox_svg_activeMove_color {
  color: white;
  // background-color: #225cc340 !important;
}
.fileTypeBox_svg_color {
  color: white;
  // background-color: #225cc3 !important;
}

.fileTypeBox_pdf_activeMove_color {
  color: white;
  // background-color: #799bb940 !important;
}
.fileTypeBox_pdf_color {
  color: white;
  // background-color: #799bb9 !important;
}

.fileTypeBox_ai_activeMove_color {
  color: white;
  // background-color: #ffecd340 !important;
}
.fileTypeBox_ai_color {
  color: white;
  // background-color: #ffecd3 !important;
}

.fileTypeBox_xd_activeMove_color {
  color: white;
  // background-color: #ffd6f640 !important;
}
.fileTypeBox_xd_color {
  color: white;
  // background-color: #ffd6f6 !important;
}

.fileTypeBox_zip_activeMove_color {
  color: white;
  // background-color: #d7f3ed40 !important;
}
.fileTypeBox_zip_color {
  color: white;
  // background-color: #d7f3ed !important;

}

.clickcss{
  cursor: pointer;
}

.playerWrapper {
  width: auto !important; // Reset width
  height: auto !important; // Reset height

}
// .ReactPlayerOveride > div {
//   text-align: center;
//   width: fit-content !important;
//   height: fit-content !important;
// }
// .ReactPlayerOveride {
//   place-content: center;
// }

.modalOveride {
  width: auto !important;
  // height: 100% !important;
  background-color: #00000065 !important;
  text-align: -webkit-center !important;
  > div:nth-child(2) {
    background: #000000e0;
    padding-top: 34px;
    border-radius: 24px;
    // border: 1px solid #535353;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);

    button {
      color: #FFFFFF;
      > span {
        line-height: 50px;
      }

      svg {
        font-size: 22px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .modalOveride {
    min-width: 95% !important;
  }
}

@media only screen and (min-width: 769px) {
  .modalOveride {
    min-width: 680px !important;
  }
}

.reactPlayer {
  border: 1px solid #ffffff1f;
}

.iconMenu {
  font-size: 2rem;
  color: rgb(124, 124, 124);
}

.directoryBox {
  color: white;
  // padding: 20px;
  // background-color: #f1f1f1 !important;
  // margin: 6px;
  border-radius: 5px;
  text-align: center;
  min-width: 32px;
  height: 32px;
}

.groupHeadName{
  color: #A0B1BD;
  font-size: 0.875rem;
  font-weight: 400;
}