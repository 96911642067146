.rootContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fafafa;
}

.mainContent {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.orderingContent {
  background-color: white;
  padding: 1.25rem;
  height: 100%;
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
  position: relative;
}

.historyContent {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 6px;
    transition: background 0.2s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9ca3af;
  }
}

.applicationSpecifications {
  flex: 1;
  border-radius: 0.5rem;
  padding: 1.25rem;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  padding: 1rem;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 0.5rem;
  }
}

.resizableHandle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 4px;
  height: 100%;
  background: #f0f0f0;
  cursor: ew-resize;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.2s ease, background-color 0.2s ease;

  &:hover {
    opacity: 1;
    background-color: #e0e0e0;
  }
}

.tabContent {
  flex: 1;
  margin-top: 1.25rem;
  overflow-y: auto;
  height: calc(100vh - 360px);
  min-height: 400px;
  padding: 0 0.5rem;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 6px;
    transition: background 0.2s ease;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #d1d5db;

      &:hover {
        background: #9ca3af;
      }
    }
  }
}

.tabContentMobile {
  height: calc(100vh - 350px);
  overflow-y: auto;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin: 8px 0;
  
  @media screen and (max-width: 768px) {
    height: calc(100vh - 280px);
    padding: 12px;
  }

  @media screen and (max-width: 480px) {
    height: calc(100vh - 220px);
    padding: 8px;
  }
}


.buttonLabel {
  color: #1c87f2;
  font-weight: 500;
  transition: color 0.2s ease;
}

.buttonStyle {
  background-color: #e6f4ff;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: #bae0ff !important;
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
}

.generateButton {
  background: #1c87f2;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1.25rem;
  transition: all 0.2s ease;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover:not(:disabled) {
    background: #0b6cd4 !important;
    transform: translateY(-1px);
  }

  &:active:not(:disabled) {
    transform: translateY(0);
  }

  &:disabled {
    background-color: #d1d5db;
    cursor: not-allowed;
  }
}

.backBotton {
  color: white;
  background: #9ca3af;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1.25rem;
  transition: all 0.2s ease;

  &:hover {
    background: #6b7280 !important;
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
}

.strongText {
  font-size: 1.125rem;
  font-weight: 600;
  color: #111827;
}

.historyText {
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  padding: 0 0.75rem;
}

.historyList {
  list-style-type: none;
  padding: 1rem 0.75rem;
  margin: 0;
  flex: 1;
}

.historyItem {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 0.75rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.375rem;
  transition: all 0.2s ease;
  color: #8D8D8D;

  &:hover {
    background: #f3f4f6;
  }
}

.historyItemActive {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 0.75rem;
  cursor: pointer;
  padding: 0.5rem;
  background: #e6f4ff;
  border-radius: 0.375rem;
  transition: all 0.2s ease;

  &:hover {
    background: #bae0ff;
  }
}

.historyItemDisable {
  pointer-events: none;
  opacity: 0.6;
}

.fileName {
  font-size: 0.875rem;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #374151;
}

.spacer {
  width: 100%;
  margin: 1.25rem 0;
  border: none;
  height: 1px;
  background-color: #e5e7eb;
}

.spacerFooter {
  margin: 0 1.25rem;
  border: none;
  height: 1px;
  background-color: #e5e7eb;
}

.headerText {
  // width: 200px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 0;
}

.headerContent {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.textStep {
  font-size: 0.875rem;
  line-height: 1.25rem;
  width: max-content;
  color: #374151;
}

.ellipsisText {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #111827;
}

.stepTailAnimation[class="ant-steps-item-container"] > div[class="ant-steps-item-tail"]::after {
  background: linear-gradient(90deg, #1c87f2 0%, #bae0ff 100%);
  animation: moveTail 2s linear infinite;
  border-radius: 20px;
}

@keyframes moveTail {
  0% {
    left: -20%;
    width: 20%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

.messageText {
  overflow: hidden;
  white-space: pre-wrap;
  animation: typing 0.5s ease;
}

@media (max-width: 768px) {
  .headerContent {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .textStep {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .tabContent {
    height: calc(100vh - 320px);
  }
}

@media (max-width: 640px) {
  .orderingContent {
    padding: 1rem;
  }

  .footer {
    padding: 0.75rem;
  }

  .buttonStyle, .generateButton, .backBotton {
    width: 100%;
    justify-content: center;
  }

  .headerText {
    width: 100%;
  }
}

.tabContent {
  padding: 16px;
  margin: 8px 0;
  min-height: 120px;
  position: relative;

  @media (max-width: 768px) {
    padding: 12px;
    margin: 6px 0;
  }
}
