.container {
    padding: 2rem;
    gap: 4rem;
    flex: 1;

    .circle {
        display: inline-block;
        background: #D9D9E3;
          margin: 10px;
          border-radius: 50%;
      }
      
      .circleInner {
        color: rgb(31, 31, 31);
        display: table-cell;
        vertical-align: middle; 
        text-align: center;
        text-decoration: none;
        height: 32px;
        width: 32px;  
        font-size: 20px;
      }

}