.modalOveride {
  // height: 500px;
  

  > div:not(:first-child):not(:last-child) {
    height: 100% !important;
    // max-height: 700px !important;
    max-height: calc(100vh - 100px) !important;
  }
  width: 700px !important;
  
}

.modalOveride {
  // height: 500px;
  > div {
    border-radius: 10px !important;
    > div> div:last-child {
      display: none;
    }
  }
  > div> div > button {
    display: none;
  }
}
.closeBtn {
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 37px;
  right: 39px;
}
.bottonBtn {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  width: 100%;
  height: 52px;
  border-radius: 3px;
  background-color: #0f4c82;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}
.bottonBtnUpload {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  width: 100%;
  height: 52px;
  border-radius: 100px;
  background-color: #0f4c82;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}
.boxCreate {
  margin: 15px;
  margin-top: 40px;
}
.boxCreateInput {
  width: 100%;
  font-size: 1.25rem;
  border-radius: 5px;
  border-width: 1px;
  padding: 10px;
  border-color: transparent;
  background-color: #f0f0f0;
}

.uploadBtn {
  /* padding: 12px 23px 12px 23px;
  text-align: center; */
  font-size: 1.875rem;
  width: 52px;
  height: 52px;
  border-radius: 5px;
  background-color: #0f4c82;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  margin-left: 15px;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.textOverflowListFileText_upload {
  // margin: auto;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  width: 99%;
  // font-size: 1rem;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.textUpload {
  font-size: 1.5625rem;
  font-weight: bold;
  margin-top: 5px;
}
.listUploadFile {
  margin-top: 30px;
  max-height: calc(100vh - 380px);
  overflow: auto;
}
.verticalCenter {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.sizeUpload {
  font-size: 1rem;
}
.closeBtnItem {
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.boxbtnUploading {
  // font-size: 1rem;
  > div {
    > div {
      margin-right: unset !important;
      padding-right: unset !important;
    }
    > span {
      position: absolute;
      right: 297px;
      top: 20px;
      font-size: 1rem;
      font-weight: bold;
      color: #0f4c82;
    }
  }
}

.boxbtnUploadingMore50 {
  // font-size: 1rem;
  > div {
    > div {
      margin-right: unset !important;
      padding-right: unset !important;
    }
    > span {
      position: absolute;
      right: 297px;
      top: 20px;
      font-size: 1rem;
      font-weight: bold;
      color: white;
    }
  }
}

.progressNormal {
  // font-size: 1rem;
  width: 95%;
  > div {
    > span {
      color: #0f4c82 !important;
    }
  }
}

.progressWarning {
  // font-size: 1rem;
  width: 95%;
  > div {
    > span {
      color: #ff9c00 !important;
    }
  }
}

.progressError {
  // font-size: 1rem;
  width: 95%;
  > div {
    > span {
      color: red !important;
    }
  }
}

.overideBlockUI {
  z-index: 9999;
  > div {
    > div {
      position: fixed;
      z-index: 1010;
      > div:last-child {
        z-index: 11;
        > div {
          > div {
            > span {
              color: #0f4c82 !important;
            }
          }
        }
      }
    }
  }
}
