.iconHeaderContainer {
  border-bottom: 1px #e9e9e9 solid;
  display: flex;
  align-items: center;
  padding: 10px;

  .contentHeaderContainer {
    display: flex;
    width: 80%;
    margin-right: 10px;
  }

  // .siderHeaderContainer {
  //   display: flex;
  //   width: 20%;
  // }
}

.configurationContainer {
  margin-top: 20px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap; /* จะทำให้ NodeTree ถ้าเกินขนาดจะตัดลงบรรทัดใหม่ */
  min-height: 200px;
  // border-bottom: 1px #e9e9e9 solid;

  // max-height: 200px; /* หรือตามความเหมาะสมของคุณ */
}

// .boxContainer {
//   // border: 1px solid #ccc;
//   padding: 10px;
//   display: flex;
//   min-height: 200px;
//   border-bottom: 1px #e9e9e9 solid;
//   flex-grow: 20px;
// }

.boxMenu {
  min-width: 40px;
  border: 1px solid #ccc; // เพิ่ม border เพื่อสร้างกรอบ
  border-radius: 4px; // เพิ่มขอบมนุษย์เพื่อให้กรอบมีมุมโค้ง
  padding: 2px; // เพิ่ม padding เพื่อขยับข้อความออกจากกรอบ
  min-width: 40px;
  flex-wrap: wrap; /* จะทำให้ NodeTree ถ้าเกินขนาดจะตัดลงบรรทัดใหม่ */
}

.boxSubMenu {
  min-width: 40px;
  border: 1px solid #ccc; // เพิ่ม border เพื่อสร้างกรอบ
  border-radius: 4px; // เพิ่มขอบมนุษย์เพื่อให้กรอบมีมุมโค้ง
  padding: 2px; // เพิ่ม padding เพื่อขยับข้อความออกจากกรอบ
  min-width: 40px;
  flex-wrap: wrap; /* จะทำให้ NodeTree ถ้าเกินขนาดจะตัดลงบรรทัดใหม่ */
}

.footerContainer {
  // margin-top: 50px;
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  .btnCancel {
    width: 40%;
    border: 0;
    border-radius: 4px;
    padding: 4px 0;
    cursor: pointer;
  }
  .btnCreate {
    width: 40%;
    border: 0;
    border-radius: 4px;
    padding: 4px 0;
    background: #1c87f2;
    color: #ffffff;
    cursor: pointer;
  }

  .btnCreate:disabled {
    background-color: #ababab; /* กำหนดสีพื้นหลังเมื่อปุ่มถูกปิดใช้งาน */
    color: black; /* กำหนดสีข้อความเมื่อปุ่มถูกปิดใช้งาน */
    cursor: not-allowed;
  }
}

.messageNotify {
  padding-left: 15px;
  font-weight: 600;
}
.descriptionNotify {
  padding-left: 15px;
  font-size: 12px;
}
.iconNotify {
  display: flex;
  align-items: center;
  height: 54px;
  width: 54px;
  margin-right: 10px;
  margin-left: -10px;
}
.iconSuccessNotify {
  font-size: 35px;
}
