.cardGroupHeader {
    font-size: 1rem;
    font-weight: 500;
    color: #36393A;
    margin-bottom: 5px;
  }
  
  .cardGroup {
    display: grid;
    // justify-content: space-between;
    // grid-template-columns: repeat(auto-fill, 363px);
    grid-gap: 10px;
    padding-top: 15px;
    padding-left: 6px;
      padding-right: 8px;
  }
  
  .addIconColor {
    // background-color: #ffffff33;
    color: white;
    // padding: 5px;
    text-align: center;
    min-width: 32px;
    height: 32px;
    border-radius: 5px;
  }
  .verticalCenter {
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .fileCardBox{
    width: 100%;
    height: 80px;
    background: rgb(255, 255, 255);
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    border: 1px solid #E7EDF0;
    display: inline-flex;
    align-items: center;
    border-radius: 10px;
    min-width: 320px;
  }
  .textOverflowListFileText {
    // margin: auto;
    color: black;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  .clickcss{
    cursor: pointer;
  }

  // .ReactPlayerOveride > div {
  //   text-align: center;
  //   width: fit-content !important;
  //   height: fit-content !important;
  // }
  // .ReactPlayerOveride {
  //   place-content: center;
  // }
  
  .modalOveride {
    width: auto !important;
    // height: 100% !important;
    background-color: #00000065 !important;
    text-align: -webkit-center !important;
    > div:nth-child(2) {
      background: #000000e0;
      padding-top: 34px;
      border-radius: 24px;
      // border: 1px solid #535353;
      -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
      -moz-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  
      button {
        color: #FFFFFF;
        > span {
          line-height: 50px;
        }
  
        svg {
          font-size: 22px;
        }
      }
    }
  }
  
  @media only screen and (max-width: 768px) {
    .modalOveride {
      min-width: 95% !important;
    }
  }
  
  @media only screen and (min-width: 769px) {
    .modalOveride {
      min-width: 680px !important;
    }
  }
  
  .reactPlayer {
    border: 1px solid #ffffff1f;
  }
  
  .iconMenu {
    font-size: 2rem;
    color: rgb(124, 124, 124);
  }
  
  .directoryBox {
    color: white;
    // padding: 20px;
    // background-color: #f1f1f1 !important;
    // margin: 6px;
    border-radius: 5px;
    text-align: center;
    min-width: 32px;
    height: 32px;
  }
  
  .groupHeadName{
    color: #A0B1BD;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .customInfinitscroll {
    overflow: visible !important;
  }
  
  .customInfinitscroll::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }
  
  .customInfinitscroll::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }
  
  .customInfinitscroll:hover {
    &::-webkit-scrollbar-thumb {
      background: #bbbfca;
    }
  }