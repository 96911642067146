.editStCompContainer {
    margin-top: 10px;
  
    .compConfig {
      margin-bottom: 30px;
  
      .compConfigTitle {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .compConfigInput {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 12px;
  
        .compInputSort {
          height: 38px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-inline-start: 10px;
          border: solid 1px #d9d9d9;
          width: 350px;
          border-radius: 6px;
  
          .sortTitle {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #d9d9d9;
          }
          .compInputSelect {
            display: flex;
            justify-content: flex-start;
          }
        }
        .compInputFilter {
            height: 38px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-inline-start: 10px;
            border: solid 1px #d9d9d9;
            width: 350px;
            border-radius: 6px;
    
            .filterTitle {
              display: flex;
              align-items: center;
              font-size: 12px;
              color: #d9d9d9;
              font-weight: 400;
            }
            .compInputSelect {
              display: flex;
              justify-content: flex-start;
            }
          }

        .createCompBtn {
          height: 38px;
          width: 46px;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          fill: #ffffff;
        }
      }
    }
    .compSelected {
      .compSelectedTitle {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .compSelectedList {
        .compSelectedItem {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: solid 1px #e9e9e9;
          padding: 6px 0;

          .componentNameWrapper{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
          }
  
          .compAction {
            height: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            gap: 14px;
  
            .genPageBtn {
              color: #ababab;
            }
            .genPageBtn:hover {
              color: #1c87f2;
            }
            .genMFBtn {
              color: #ababab;
            }
            .genMFBtn:hover {
              color: #1c87f2;
            }
            .actionIconWrapper {
              height: 32px;
              width: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border-radius: 6px;
              border: solid 1px #e9e9e9;
  
              .actionIcon {
                fill: #ababab;
              }
            }
            .actionIconWrapper:hover {
              border-color: #1c87f2;
  
              .actionIcon {
                fill: #1c87f2;
              }
            }
          }
        }
      }
    }
  }
  