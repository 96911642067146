.cardContainer {
  height: 100%;
  border-radius: 16px;
  margin: 16px 16px;
}

.desc {
  color: #637381;
  font-size: 14px;
}

.summaryPackage {
  color: #212B36;
  font-weight: 600;
  font-size: 14px;
}

.summaryDetail {
  color: #212B36;
  font-size: 14px;
}

.summaryTotal {
  color: #212B36;
  font-weight: 600;
  font-size: 16px;
}
