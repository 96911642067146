.modalContainer {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    display: inline-flex;
    margin-bottom: 32px;
  }

  
  .titleText {
    width: 100%;
    align-self: stretch;
    text-align: center;
    color: #101828;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    word-wrap: break-word;
  }
  
  .descriptionText {
    width: 100%;
    align-self: stretch;
    flex: 1 1 0;
    text-align: center;
    color: #475467;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }