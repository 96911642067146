.container {
  height: 100%;
}

.title {
  color: black;
  font-size: 14px;
  font-weight: 400;
}

.cardGrid {
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.customTabBar {
  background-color: #fffefe;
  padding-top: 10px;
  // border-bottom:none;
}

.customScrollbar {
  width: 100%;
  // height: calc(100vh - 160px);
  height: 100%;
  overflow: auto;
  position: relative;
}

.customScrollbar::-webkit-scrollbar {
  display: none;
}

.centeredColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.centeredText {
  text-align: center;
  padding-bottom: 30px;
}

.infoText {
  color: #ABABAB;
  font-size: 14px;
  margin-top: -35px;
}

.headerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
