.tagBtnActive {
    background-color: #1C87F2;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;
    border: none;
    cursor: pointer;
  }
  
  .tagBtn {
    background-color: rgba(28, 135, 242, 0.15);
    color: #1C87F2;
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;
    border: none;
    cursor: pointer;
  }
  