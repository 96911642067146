.MyCenterLayout {
  height: calc(100vh);
  width: 100%;
}

.MyCenterLayoutFromApps {
  height: 100%;
  position: relative;
}

.usernameProfile {
  margin: auto;
  font-size: 0.7rem;
  color: #7c7c7c;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nameProfile {
  margin: auto;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ProfileImg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-color: rgb(158, 158, 158);
  border-style: solid;
  border-width: 1px;
}

// .boxLeftMenu {
//     position: fixed;
//     border: 1px solid #F5F5F5;
//     box-shadow: 0px -2px 10px rgba($color: #000000, $alpha: 0.16);
// }
// .content {
//     margin-left: 345px;
//     margin-top: 30px;
//     margin-bottom: 30px;
//     max-width: calc(94% - 290px - 504px);
// }
// .personalImg {
//     width: 100px;
//     height: 100px;
//     border-radius: 50%;
//     background-repeat: no-repeat;
//     background-size: cover;
//     border-color: rgb(158, 158, 158);
//     border-color: rgb(158, 158, 158);
//     border-style: solid;
//     border-width: 1px;
// }
// .nameProfile {
//     margin: auto;
//     font-size: 1rem;
//     font-weight: 600;
//     white-space: nowrap;
//     width: 80%;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
// .usernameProfile {
//     margin: auto;
//     font-size: 18px;
//     color: #7C7C7C;
//     white-space: nowrap;
//     width: 80%;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }
// .menuItem {
//     height: 50px!important;
//     width: 100%;
//     display: inline-flex;
//     align-items: center;
//     margin: auto;
// }
// .iconTabMenu {
//     width: 50px;
//     margin-left: 18px;
// }
// .fontTabMenu {
//     color: #000000;
//     font-size: 1rem;
// }
.fontTabMenu {
  margin-top: 5px;
}
.fontTabMenuRequest{
  margin-top: 0px !important;
}

@media only screen and (max-width: 768px) {
  .boxLeftMenu {
    display: none;
  }

  .boxLeftMenuShort {
    display: none;
  }

  .content {
    margin-left: 0px;
    width: 100%;
    //margin-top: 50px;
    overflow-y: auto;
  }

  .responsiveMenu {
    width: 100%;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50px;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: 0 5px 5px -5px #333;
    overflow-x: auto;
    overflow-y: hidden;

    .menu {
      width: 50px;
      min-width: 40px;
      height: 50px;
      min-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      .menuIcon {
        width: 25px;
        height: 25px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .checkIn {
      filter: grayscale(100%);
    }

    .menuActive {
      > div {
        filter: contrast(300%);
      }
      background-color: #bebebe !important;
    }
  }
}

@media only screen and (min-width: 769px) {
  // .hiddenRightMenu {
  //   display: none;
  // }

  .responsiveMenu {
    display: none;
  }

  .buttonRightMenu {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #0f4c82;
    position: absolute;
    z-index: 100;
    right: 5px;
    top: 55px;
    cursor: pointer;
  }
  .rightMenuShortContent {
    height: 100%;
    background-color: #ffffff;
    position: fixed;
    right: 40px;
    top: 75px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.6);
    border-radius: 15px 0px 15px 15px;
  }
  .content {
    margin-left: 140px;
    // margin-top: 30px;
    // margin-bottom: 30px;
    width: 100%;
    max-width: calc(100% - 0px);
    height: calc(100vh - 50px);
    background: #f0f2f5;
    overflow-y: auto;
  }
  .boxLeftMenu {
    display: none;
  }
  .boxLeftMenuShort {
    position: fixed;
    border: 1px solid #f5f5f5;
    box-shadow: 0px -2px 10px rgba($color: #000000, $alpha: 0.16);
  }
  .personalImg {
    width: 77px;
    height: 77px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-color: rgb(158, 158, 158);
    border-color: rgb(158, 158, 158);
    border-style: solid;
    border-width: 1px;
  }
  .nameProfile {
    margin: auto;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .menuItem {
    height: 50px !important;
    width: 100%;
    // display: inline-flex;
    align-items: center;
    margin: auto;
  }
  .iconTabMenu {
    width: 60px;
    text-align: center;
  }
  .fontTabMenu {
    color: #000000;
    font-size: 0.8rem;
  }
  .fontTabPost{
    margin-top: 5px;
  }
  .leftMenu {
    margin-top: 8px;
    overflow-y: auto;
    height: calc(100vh - 300px);
  }
}

@media only screen and (min-width: 1000px) {
  .responsiveMenu {
    display: none;
  }

  .hiddenRightMenu {
    display: none;
  }
  .buttonRightMenu {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #0f4c82;
    position: absolute;
    z-index: 100;
    right: 5px;
    top: 55px;
  }
  .rightMenuShortContent {
    height: 100%;
    background-color: #ffffff;
    position: fixed;
    right: 40px;
    top: 90px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.6);
    border-radius: 15px 0px 15px 15px;
  }
  .content {
    margin-left: 210px;
    // margin-top: 30px;
    // margin-bottom: 30px;

    width: 100%;
    max-width: calc(90% - 100px);
    height: calc(100vh - 50px);
    background: #f0f2f5;
    overflow-y: auto;
  }
  .boxLeftMenu {
    display: block;
    position: fixed;
    border: 1px solid #f5f5f5;
    box-shadow: 0px -2px 10px rgba($color: #000000, $alpha: 0.16);
  }
  .boxLeftMenuShort {
    display: none;
  }
  .personalImg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-color: rgb(158, 158, 158);
    border-color: rgb(158, 158, 158);
    border-style: solid;
    border-width: 1px;
  }
  .nameProfile {
    margin: auto;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .menuItem {
    height: 50px !important;
    width: 100%;
    // display: inline-flex;
    align-items: center;
    margin: auto;
  }
  .iconTabMenu {
    width: 50px;
    margin-left: 18px;
  }
  .fontTabMenu {
    color: #000000;
    font-size: 0.8rem;
  }
  .leftMenu {
    margin-top: 8px;
    overflow-y: auto;
    height: calc(100vh - 360px);
  }
}

@media only screen and (min-width: 1200px) {
  .responsiveMenu {
    display: none;
  }

  .hiddenRightMenu {
    display: block;
  }
  // .buttonRightMenu {
  //   display: none;
  // }
  // .rightMenuShortContent {
  //   display: none;
  // }
  .content {
    margin-left: 210px;
    // margin-top: 30px;
    // margin-bottom: 30px;
    width: 100%;
    max-width: calc(100% - 100px - 0px);
    height: calc(100vh - 50px);
    background: #f0f2f5;
    overflow-y: auto;
  }
  .boxLeftMenu {
    position: fixed;
    border: 1px solid #f5f5f5;
    box-shadow: 0px -2px 10px rgba($color: #000000, $alpha: 0.16);
  }
  .boxLeftMenuShort {
    display: none;
  }
  .personalImg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-color: rgb(158, 158, 158);
    border-color: rgb(158, 158, 158);
    border-style: solid;
    border-width: 1px;
  }
  .nameProfile {
    margin: auto;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .menuItem {
    height: 50px !important;
    width: 100%;
    // display: inline-flex;
    align-items: center;
    margin: auto;
  }
  .iconTabMenu {
    width: 50px;
    margin-left: 18px;
  }
  .fontTabMenu {
    color: #000000;
    font-size: 0.8rem;
  }
  .leftMenu {
    margin-top: 8px;
    overflow-y: auto;
    height: calc(100vh - 360px);
  }
}
.placeName {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.checkInButton {
  // padding: 0.3rem 0.75rem;
  width: 100%;
  margin: auto;
  padding: 0 0.75rem;
  // background-color: #184d80;
  // color: #fff;
  // border-radius: 15px;
  // cursor: pointer;
  // font-size: 1rem;
}

//scrollbar

.leftMenu::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.leftMenu::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.leftMenu:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

.boxMain {
  display: block;
  // text-align: -webkit-center;
  background-color: #f8f8f8;
}

// .shortContent{

// }

.shortContent::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.shortContent::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.shortContent:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

// @media only screen and (max-width: 1100px) {
//   .MyCenterLayoutFromApps{
//     background-color: red;
//   }
// }