.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.card {
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
}

.selected {
  background-color: #f0f7ff;
  border-color: #1890ff;
}

.documentTitle {
  width: 80%;
  font-size: 0.9rem;
  color: #333;
  margin-bottom: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.documentMeta {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.8rem;
  color: #666;
}

.fileSize {
  white-space: nowrap;
}

.date {
  white-space: nowrap;
}

.iconContainer {
  width: 10%;
  display: flex;
  gap: 0.5rem;
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.pdf {
    color: #ff4d4f;
  }

  &.doc {
    color: #1890ff;
  }
}

