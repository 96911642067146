.boxMenuTabActive {
  background-color: #e8e8e8;
}

.screenPage {
  width: 100%;
}

.boxMainFileCommunity {
  padding-top: 10px;
  // padding-right: 30px;
  // padding-right: 285px;
  // padding-left: 30px;
  // padding-right: 30px;
  padding-bottom: 0px;
}

.boxMainFile {}

.FileContent {
  // background-color: white;
  color: #36393A;
  //min-height: 100vh;
  width: 100%;
}

.headerContainer {
  height: 84px;
  color: #121927;
  font-size: 20px;
  font-weight: 500;
  line-height: 42px;
  text-align: left;
  padding: 20px 50px;
  background: #fff;
  border-bottom: 1px solid #DCDCDC;
}

.headerGroup {
  width: 100%;
  display: inline-flex;
  margin-top: 30px;
  margin-bottom: 0px;
  //   top: 50px;
  //   padding-bottom: 48px;
  align-items: center;
  justify-content: space-between;
}

.searchBox {
  //   margin-left: 25px;
  margin-right: 30px;
  width: 100%;
}

.inputSearchBox {
  /* width: 100%; */
  height: 48px;
  border-radius: 8px;
  // border: none;
  color: #afafaf;
  font-size: 1.1875rem;
  padding-left: 18px;
  border: solid 1px #DADCE2;
}

.card {
  // width: 400px;
  background-color: #bcbcbc;
  max-width: 48px;
  height: 48px;
  border-radius: 10px;
  padding: 25.8px 20px 23.9px 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.backIcon {
  width: 20px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin: 20px 0 10px 0;
}

.symbolPath {
  margin-right: 5px;
  margin-left: 5px;
  color: #bebebe;
}

.boxPath {
  display: flex;
  font-size: 1rem;
  margin-bottom: 15px;
  margin-top: 15px;

  width: 100%;
  display: inline-flex;
  align-items: center;
}

.pathNotActive {
  color: #bebebe;
  cursor: pointer;
}

.pathActive {
  color: #000000;
}

.pathSpotsNotActive {
  color: #bebebe;
  pointer-events: none;
}

.textOverflowListFileText {
  // margin: auto;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: 1rem;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.FileContent::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.FileContent::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.FileContent:hover {
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

.tabs {
  width: 320px;
  height: calc(89vh - 0px);
  // background-color: rgb(255, 255, 255);
  // position: fixed;
  right: 40px;
  // padding: 15px;
  overflow-y: auto;
  // max-height: 740px;
  top: 60px;
  color: #36393A;
  // border-left: #e7e6e6 solid 2px;
  border-radius: 20px;
  background: #fff;
  border: 1px solid #E7EDF0;
  // padding-left: 30px;
  margin: 10px 30px 30px 30px;
}

.tabs::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.tabs::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.tabs:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.tabsCommunity {
  width: 320px;
  height: calc(89vh - 0px);
  // background-color: rgb(255, 255, 255);
  // position: fixed;
  right: 40px;
  // padding: 15px;
  overflow-y: scroll;
  // max-height: 740px;
  top: 60px;
  color: #0f4c82;

  border-left: #e7e6e6 solid 2px;
  // padding-left: 30px;
  // margin-left: 30px;
  // position: fixed;
}

.tabsCommunity::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.tabsCommunity::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.tabsCommunity:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.overrideTab {
  >div:first-child {
    >div {
      >div {
        width: 100%;

        >div {
          width: 100%;
          justify-content: center;
          // border-bottom: 2px #e7e6e6 solid;
          margin: 0 0px 0 0 !important;

          >div {
            color: #36393A;
            font-weight: 400;
          }
        }

        >div:last-child {
          background: unset !important;
          font-weight: 300;
          border-bottom: 2px solid #104d82;
          // width:unset  !important;
        }
      }
    }

    >div:last-child {
      display: none !important;
    }
  }
}

.boxMenuBottom {
  position: fixed;
  bottom: 0px;
  padding: 12px;
  background: #00000080;
  width: calc(100% - 56px);
  color: white;
  font-size: 0.9rem;
  z-index: 99999;
  left: 56px;
}

.boxMenuBottomMenuActive {
  position: absolute;
  bottom: 0px;
  padding: 12px;
  background: #00000080;
  width: calc(100% - 230px);
  color: white;
  font-size: 0.9rem;
  z-index: 99999;
  left: 230px;
}

.rightcss {
  float: right;
}

.leftcss {
  float: left;
}

.itemMenu {
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.boxMainFile {
  // display: contents;
  display: flex;
  flex-direction: column;
}

.backMainChild {
  padding-top: 15px;
  // padding-right: 98px;
  padding-left: 25px;
  padding-bottom: 30px;
  width: 100%;
  display: flex;
  background: #f9f9f9;
}

.backMainChildCom {
  padding-top: 15px;
  // padding-right: 60px;
  padding-left: 25px;
  padding-bottom: 0px;
  width: 100%;
  display: flex;
}

.boxFile {
  padding-right: 25px;
}

@media only screen and (max-width: 768px) {
  .overrideTab {
    >div:first-child {
      >div:first-child {
        >div:first-child {
          >div:first-child {
            display: inline-flex;
          }
        }
      }
    }
  }

  .screenLinkPage {
    width: calc(100%);
    padding-right: 300px;
  }

  /* For tablets: */
  .menuFileMobile {
    display: block !important;
  }

  .menuFileWeb {
    display: none !important;
  }

  .boxFile {
    width: 100%;
    padding-right: 0px;
    padding-bottom: 50px;
  }

  .boxMenuBottom {
    width: 100% !important;
    left: unset !important;
  }

  .backMainChild {
    padding-top: 15px;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 0px;
    width: 100%;
    display: flex;
  }

  .backMainChildCom {
    padding-top: 15px;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 0px;
    width: 100%;
    display: flex;
  }

  .boxFileTabComLink {
    padding-right: 20px;
    width: calc(80vw - 10px);
  }
}

@media only screen and (min-width: 769px) {
  /* For tablets: */

  .overrideTab {
    >div:first-child {
      >div:first-child {
        >div:first-child {
          >div:first-child {
            display: none;
          }
        }
      }
    }
  }

  .screenLinkPage {
    width: calc(100%);
    padding-right: 300px;
  }

  .menuFileMobile {
    display: none !important;
  }

  .menuFileWeb {
    display: block !important;
  }

  .boxFile {
    width: 100%;
    padding-right: 0px;
    padding-bottom: 50px;
  }

  .boxMainFile {
    // padding: 60px;
    //padding-top: 60px;
    color: #36393A;
  }
}



@media only screen and (min-width: 1280px) {
  .boxFile {
    width: 100%;
    padding-bottom: 50px;
    // padding-right: 40px;
  }
}

@media only screen and (max-width: 1100px) {
  .screenLinkPage {
    width: calc(100%);
    padding-right: 50px;
  }

  .boxMainFileCommunity {
    // padding: 10px;
    padding-top: 0px;
  }

  .boxFileTabComLink {
    padding-right: 20px;
    width: calc(47vw - 10px);
  }

  .tabsCommunity {
    top: 138px;
  }

  .backMainChild {
    background-color: #F9F9F9;
    padding-top: 15px;
    // padding-right: 90px;
    padding-left: 25px;
    padding-bottom: 0px;
    width: 100%;
    display: flex;
  }

  .backMainChildCom {
    padding-top: 15px;
    // padding-right: 90px;
    padding-left: 25px;
    padding-bottom: 0px;
    width: 100%;
    display: flex;
  }
}

.emptyTrash {
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin: 5px 0 5px 0;
  position: relative;
  top: -3px;
}

.backIconMain {
  flex: 0;
  // padding-left:25px;
}

.emptyTrashText {
  font-size: 1.4375rem;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: #3875F6;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.boxFileTab {
  padding-right: 25px;
  overflow: auto;
  height: calc(100vh - 192px);
}

.boxFileTabComLink {
  overflow: auto;
  height: calc(100vh - 150px);
  width: calc(40vw - 10px);
}

.boxFileTabCom {
  padding-right: 20px;
  overflow: auto;
  height: calc(100vh - 150px);
}

.boxFileTabMedia {
  overflow: auto;
  height: calc(100vh - 192px);
  // padding-left: 8px;
}

.boxFileTabComMadia {
  overflow: auto;
  height: calc(100vh - 150px);
  padding-left: 8px;
}


.moduleContainer {
  display: -webkit-box;
  grid-gap: 10px;
  gap: 10px;
  // padding-bottom: 20px;
  overflow-x: auto;
  padding-bottom: 10px;
  padding-top: 5px;
  padding-right: 30px;
}

.moduleItem {
  border-radius: 20px;
  background-color: white;
  font-weight: 500;
  color: #0f4c82;
  text-align: center;
  padding: 5px 15px;
  cursor: pointer;
  border-color: #0f4c82;
  border: solid;
  border-width: thin;
}

.moduleItemActive {
  border-radius: 20px;
  background-color: #0f4c82;
  font-weight: 500;
  color: white;
  text-align: center;
  padding: 5px 15px;
  cursor: pointer;
  border: solid;
  border-width: thin;
}

.overrideTab .ant-tabs-tab-active {
  border-bottom: 3px solid #3875F6 !important;
}

