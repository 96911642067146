.billingCard {
    // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
    padding: 8px 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .titleSection {
        h4 {
            font-size: 24px;
            font-weight: 600;
            color: #111827;
            margin-bottom: 4px;
        }

        p {
            color: #6b7280;
            font-size: 14px;
        }
    }

    .controls {
        display: flex;
        gap: 16px;
        align-items: center;

        :global {
            .ant-picker {
                border-radius: 8px;
                border-color: #e5e7eb;
            }

            .ant-select {
                min-width: 140px;

                .ant-select-selector {
                    border-radius: 8px;
                    border-color: #e5e7eb;
                }
            }

            .ant-btn {
                border-radius: 8px;
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }
}

.table {
    :global {
        .ant-table {
            border: none;
        }

        .ant-table-container {
            border: none !important;
        }

        // .ant-table-cell {
        //     border: none !important;
        // }

        .ant-table-thead>tr>th {
            background-color: transparent;
            color: #1f2937;
            font-weight: 700;
            font-size: 14px;
            padding: 12px 16px;
            text-transform: uppercase; // This will make all header text uppercase
        }
        th.ant-table-cell {
             position: static !important;
        }
    }
}

.evenRow {
    background-color: #ffffff;
}

.oddRow {
    background-color: #f8fafc;
}


.amount {
    font-weight: 500;
}

.invoice {
    font-weight: 500;
    color: #111827;
}