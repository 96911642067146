.createEditPageContainer {
  width: 100%;
  height: 65vh;
  display: flex;

  .toolSideContainer {
    padding: 15px;
    background: #f8f8f8;
    display: flex;
    align-items: center;

    .toolSideWrapper {
      border: solid #e9e9e9 1px;
      width: 35%;
      height: 100%;
      min-width: 250px;
      max-width: 300px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0px 2px 10px 0px #e9e9e9;

      .titleWrapper {
        height: 30px;
        padding: 16px;
        background: #1875f00d;
        font-weight: 500;
      }
      .searchWrapper {
        width: 100%;
        height: 40px;
        background: #fff;
        position: sticky;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 15px;
        margin-top: 10px;

        .searchInput {
          font-size: 14px;
          border-radius: 8px;
        }
      }
      .contentWrapper {
        padding: 16px;
        height: 50vh;
        overflow-y: scroll;
        scrollbar-width: none;

        .boFieldWrapper {
          margin: 5px 0 10px 0;
          padding-bottom: 10px;
          border-bottom: solid 1px #e9e9e9;

          .titleWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fff;
            cursor: pointer;

            .title {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .boItems {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-top: 10px;

            .boItem {
              font-size: 14px;
              font-weight: 500;
              border: solid #e9e9e9 1px;
              padding: 10px;
              border-radius: 8px;
              cursor: pointer;

              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .boItemsHide {
            display: none;
          }
        }

        .controlWrapper {
          margin: 5px 0 10px 0;
          .titleWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fff;
            cursor: pointer;

            .title {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .controlItems {
            // display: flex;
            // flex-direction: column;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 10px;
            padding-top: 10px;
            .controlItem2Col {
              grid-column: span 2;

              font-size: 14px;
              font-weight: 500;
              border: solid #e9e9e9 1px;
              padding: 10px 0;
              border-radius: 8px;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              gap: 5;
              cursor: grab;

              .controlItemText {
                // margin-left: 10px;
              }
            }
            .controlItem {
              grid-column: span 1;

              font-size: 14px;
              font-weight: 500;
              border: solid #e9e9e9 1px;
              padding: 10px 0;
              border-radius: 8px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 5;
              cursor: grab;

              .controlItemText {
                // margin-left: 10px;
              }
            }
          }
          .controlItemsHide {
            display: none;
          }
        }
      }
    }
  }

  .contentSideContainer {
    flex: 1;
    // border: solid #E9E9E9 1px;
    background: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65vh;
    width: 55vw;
    overflow: auto;
    scrollbar-width: thin;
    padding: 10px;

    // .pageConfigWrapper {
    //   width: 500px;
    //   height: 400px;
    //   background: #fff;
    //   display: grid;
    //   grid-template-columns: repeat(2, 1fr);
    //   .pageConfigItem {
    //     cursor: pointer;
    //     border: #bebebe solid 1px;
    //   }
    //   .pageConfigItem:hover {
    //     border: #1c87f2 solid 2px;
    //   }
    // }

    .pageConfigWrapper {
      position: relative;
      height: -webkit-fill-available;
      // width: 100%;
      // height: 100%;
      .table {
        // height: 100%;
        // width: auto;
        min-width: 40vw;
        min-height: 50vh;
        // border-collapse: collapse;
        border-spacing: 0.5px;

        .tableTr {
          background: #fff;
          .tableTd {
            width: 150px;
            height: 60px;
            border: #bebebe solid 1px;
            cursor: pointer;
            padding: 10px;
            z-index: 0;

            .insideTableTdFocus {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              border: #1c87f2 solid 1px;
              border-radius: 6px;
              background: #fff;
            }
            .insideTableTd {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              border: #bebebe solid 1px;
              z-index: 1;
              border-radius: 6px;
              background: #fff;
            }
            .insideTableTd:hover {
              border: #1c87f2 solid 1.5px;
            }
          }
          .tableTd:hover {
            border: #1c87f2 solid 1.5px;
          }
          .tableTdDragOver {
            background: rgba(28, 135, 242, 0.5) !important;
          }
          .tableTdFocus {
            border: #1c87f2 solid 1px;
            background: #e8f4ff !important;
          }
          .tableTdComponentFocus {
            padding: 10px;
            border: #1c87f2 solid 1px;
            background: #e8f4ff !important;
          }
        }
      }

      .addRowBtnContainer {
        display: flex;
        justify-content: center;
        margin: 10px 0;

        .addRowBtn {
          position: static;
          bottom: -40px;
          left: 48%;
          height: 32px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 6px;
          border: solid 1px #e9e9e9;
          background: #fff;
  
          .actionIcon {
            fill: #1c87f2;
          }
        }
      }

      
      .addColBtn {
        position: absolute;
        right: -40px;
        top: 5px;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 6px;
        border: solid 1px #e9e9e9;
        background: #fff;

        .actionIcon {
          fill: #1c87f2;
        }
      }
    }
  }
}
