.storyEmptyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bgImgWrapper {
    width: 250px;
    height: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .bgImg {
      width: 100%;
      height: 100%;
    }
    .bgTxt {
      position: absolute;
      bottom: 10px;
      z-index: 10;
      color: var(--text-color-gray)
    }
  }
  .createBtn {
    cursor: pointer;
  }
}
