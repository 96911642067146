.modalOveride {
  // height: 500px;
  > div {
    border-radius: 10px !important;

    > div:last-child {
      // display: none;
    }
  }

  // > div:not(:first-child):not(:last-child) {
  //   // max-height: 700px !important;
  //   // height: 100% !important;
  //   // max-height: calc(100vh - 100px) !important;
  // }

  width: 350px !important;
  > div > button {
    // display: none;
  }
}
.modalOveride {
  // height: 500px;
  > div {
    border-radius: 10px !important;
    > div> div:last-child {
      display: none;
    }
  }
  > div> div > button {
    display: none;
  }
}

.modalContainer {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.buttonContainer {
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  width: 100%;
}

.cancelBtn {
  width: 120px;
  height: 40px;
  background-color: #ececec;
  color: #000000;
  font-size: 1rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  outline: none;
}

.addBtn {
  width: 120px;
  height: 40px;
  background-color: #0f4c82;
  color: #ffffff;
  font-size: 1rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  outline: none;
}
