.cardGroupHeader {
  font-size: 1rem;
  font-weight: 500;
  color: #36393A;
}

.cardGroup {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-gap: 15px 7px;
  padding-top: 20px;
}
.boxBig {
  background-size: cover;
  // width: 230px;
  height: 100px;
  padding: 16px 16px 0px;
}
.boxBigFile {
  display: inline;
  text-align: center;
  align-content: center;
  transform: translate(0%, 10%);
  >div{
    padding-top: 18px;
  }
}

.boxBigImg{
  text-align: center;
  align-content: center;
  // transform: translate(0%, 63%);
}

.boxTextIcon{
  display: inline-flex;
  align-items: center;
  margin: auto;
  margin-left: 0px;
}

.textOverflowListFileText {
  // margin: auto;
  color: #36393A;
  white-space: nowrap;
  overflow: hidden;
  width: 87%;
  // font-size: 1rem;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.modalOveride {
  width: auto !important;
  // height: 100% !important;
  // background-color: #00000065 !important;
  text-align: -webkit-center !important;
  > div:nth-child(2) {
    background: #000000e0;
    padding-top: 34px;
    border-radius: 24px;
    // border: 1px solid #535353;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);

    button {
      color: #FFFFFF;
      > span {
        line-height: 50px;
      }

      svg {
        font-size: 22px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .modalOveride {
    min-width: 95% !important;
  }
}

@media only screen and (min-width: 769px) {
  .modalOveride {
    min-width: 680px !important;
  }
}

.reactPlayer {
  border: 1px solid #ffffff1f;
}


.recendbox{
  width: 250px;
  height: 170px;
  background: rgb(255, 255, 255);
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
  border: 1px solid #E7EDF0;
  font-size: 0.9rem;
  font-weight: 400;
  border-radius: 8px;
}
.iconMenu {
  font-size: 2rem;
  color: rgb(124, 124, 124);
}

@media only screen and (min-width: 500px) {


  .recendbox{
    width: 250px;
  }
  .cardGroup{
    grid-template-columns: repeat(auto-fill, 250px);
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media only screen and (max-width: 499px) {
  .recendbox{
    width: 100%;
  }
  .cardGroup{
    grid-template-columns: repeat(auto-fill, 100%);
    padding-left: 5px;
    padding-right: 5px;
  }
}