.code-container {
    border: 1px solid #3c3c3c;
    border-radius: 4px;
    overflow: hidden;
}

.code-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #252526;
    padding: 8px 16px;
    border-bottom: 1px solid #3c3c3c;
}

.file-tab {
    display: flex;
    align-items: center;
}

.file-name {
    color: #d4d4d4;
    font-size: 12px;
}

.toolbar-actions {
    display: flex;
    gap: 8px;
}

.toolbar-button {
    background-color: transparent;
    border: 1px solid #3c3c3c;
    color: #d4d4d4;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        background-color: #3c3c3c;
    }

    &:active {
        transform: translateY(1px);
    }
}

.copy-button {
    background-color: transparent;
    border: 1px solid #3c3c3c;
    color: #d4d4d4;
    padding: 4px 8px;
    border-radius: 3px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
        background-color: #3c3c3c;
    }
}

.code-content {
    display: flex;
    background-color: #1E1E1E;
}

.line-numbers {
    padding: 8px 0;
    border-right: 1px solid #3c3c3c;
    background-color: #1E1E1E;
}

.line-number {
    display: block;
    text-align: right;
    color: #858585;
    padding: 0 8px;
    font-size: 13px;
    font-family: Consolas, Monaco, "Andale Mono", monospace;
}

.code-line {
    &:hover {
        background-color: #2d2d2d;
    }
}