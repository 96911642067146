.container {
  width: auto;
  height: auto;
  border-bottom: 1px #e9e9e9 solid;
  padding: 10px;
}

.componentName {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardContainer {
  height: 180px;
  width: 200px;
  margin-right: 20px; /* ปรับขนาดของระยะห่างตามความต้องการ */
  margin-bottom: 20px; /* ปรับขนาดของระยะห่างตามความต้องการ */
  background: #fafafa;
}

.radioContainer {
  margin-top: 20px; /* ปรับขนาดของระยะห่างตามความต้องการ */
  margin-left: 100px;
}

.checkboxContainer {
  display: flex;
  margin-top: 10px;
  margin-left: 20px;
  grid-gap: 20px;
}

.buttonGenerate {
  margin-top: 25px;
  width: 150px;
  height: 40px;
}

.messageNotify {
  padding-left: 15px;
  font-weight: 600;
}
.descriptionNotify {
  padding-left: 15px;
  font-size: 12px;
}
.iconNotify {
  display: flex;
  align-items: center;
  height: 54px;
  width: 54px;
  margin-right: 10px;
  margin-left: -10px;
}
.iconSuccessNotify {
  font-size: 35px;
}

.btnContainer{
  display: flex;
  align-items: center;
}