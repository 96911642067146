.modalOveride {
    // height: 500px;
    > div {
      border-radius: 10px !important;
      > div> div:last-child {
        display: none;
      }
    }
    > div> div > button {
      display: none;
    }
  }

.closeBtn {
    width: 25px;
    height: 25px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.boxCreate {
    margin-top: 10px;
    box-sizing: border-box;

}
.boxCreateInput {
    // width: 100%;
    font-size: 1.25rem;
    border-radius: 5px;
    border-width: 1px;
    padding: 10px;
    border-color: transparent;
    background-color: #fff;
    border: 1px solid #E7EDf0;
}