.modalTitle {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 5px;
    font-size: 24px;
    padding-bottom: 10px;
    border-bottom: 1.5px solid #d9d9d9;
}

.modalFooter {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;

    .conBtn {
        height: 100%;
        width: 20%;
        background: #1c87f2;
        color: #ffffff;
        border-radius: 8px;
    }
    .cancelBtn {
        height: 100%;
        width: 20%;;
        border-radius: 8px;
        padding: 0 8px;
    }
}

.modalContent{
    // border: 1px solid #d9d9d9;
    border-radius: 15px;
    // min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    .divInputUploadfile{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 15px 0;

        span{
           

            .css-dev-only-do-not-override-98ntnt.ant-upload.ant-upload-drag{
                background: #ffffff;
                margin: 10px 0;
            }

            .ant-upload-text{
                font-weight: 700;
            }

        }
    }

    .conBtn {
        height: 36px;
        width: 120px;
        background: #1c87f2;
        color: #ffffff;
        border-radius: 8px;
    }

}

.Dragger{
    width: 100%;

    .divContainerEl{
        width: 100%;
        padding: 40px 0;
    }
}

.Dragger:nth-child(2){
    color: red($color: #000000);
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover {
    background-color: transparent !important;
    cursor: default !important;
  }
  
  /* Additional styles to ensure hover effect is disabled */
  .ant-upload-list-item {
    transition: none !important;
  }
