.editStDbContainer {
  margin-top: 10px;

  .dbConfig {
    margin-bottom: 30px;
    .dbConfigTitle {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .dbConfigSelect {
    }
  }

  .dbSelected {
    .dbSelectedTitle {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .dbSelectedList {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
}

.DBSelectedItem {
  width: 188px;
  height: 60px;
  padding: 16px;
  border-radius: 10px;
  border: solid 2px #1c87f2;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .dbIcon {
    display: flex;
    align-items: center;
  }
  .dbName {
    font-size: 16px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .delIconWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    .delIcon {
        fill: #E9E9E9;
    }
    .delIcon:hover {
        fill: #1677ff;
    }
  }
}
