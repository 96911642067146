
.textInputContainer {
    display: flex;
    align-items: center;

    .textHeader {
        font-size: 14px;
        margin: 0;
    }

    .textImportant {
        color: red;
    }
}

.textInputContainer2 {
    display: flex;
    margin-top: 10px;
    align-items: center;

    .textHeader {
        font-size: 14px;
        margin: 0;
    }

    .textImportant {
        color: red;
    }
}
.inputStory {
    height: 38px;
    width: 100%;
    margin-top: 5px;
    font-size: 15px;
}

.footerContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .btnCancel {
        width: 40%;
        border: 0;
        border-radius: 4px;
        padding: 4px 0;
        cursor: pointer;
    }
    .btnCreate {
        width: 40%;
        border: 0;
        border-radius: 4px;
        padding: 4px 0;
        background: #1C87F2;
        color: #FFFFFF;
        cursor: pointer;
    }

      .btnCreate:disabled {
        background-color: #ABABAB; /* กำหนดสีพื้นหลังเมื่อปุ่มถูกปิดใช้งาน */
        color: black; /* กำหนดสีข้อความเมื่อปุ่มถูกปิดใช้งาน */
        cursor: not-allowed;
      }
}

.radioGroup{
    width: 100%;
    .radioContainer {
        display: flex;
        //  gap: 30px;
        margin-top: 10px;
        width: 100%;
        border: solid 1px #E9E9E9;
        padding: 12px;
        border-radius: 8px;
        font-size: 15px;

    }

}
