.messageNotify {
    padding-left: 15px;
    font-weight: 600;

}
.descripNotify {
    padding-left: 15px;
    font-size: 12px;
}
.iconNotify {
display: flex;
align-items: center;
height: 54px;
width: 54px;
margin-right: 10px;
margin-left: -10px;
}
.iconSuccessNotify {
font-size: 35px;
}