.zard-gallery {
  padding: 0;
}
.zard_gallery_container {
  display: -ms-flexbox; /* IE10 */
  display: grid;
  box-sizing: border-box;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 20px;
}
.zzard-grallery-caption {
  display: none;
  // padding: 10px;
}

.zard_gallery_tile:active {
  cursor: wait;
}

.zard_gallery_tile:hover .zzard-grallery-caption {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1.23em;
}
.zard-gallery-content::before {
  content: "";
  display: block;
  padding-top: 100%; /* initial ratio of 1:1*/
}
.zard-gallery-content {
  display: flex;
}
// .zard_gallery_tile {
//   flex: 33.33% 1;
//   max-width: 33.33%;
//   box-sizing: border-box;
//   align-items: center;
//   // padding: 8px;
//   position: relative;
//   cursor: pointer;
// }

// @media only screen and (min-width: 576px) {
//   .zard_gallery_tile {
//     flex: 33.33% 1;
//     max-width: 33.33%;
//     padding: 10px;
//   }
// }
// @media only screen and (min-width: 1421px) {
//   .zard_gallery_tile {
//     flex: 33.33% 1;
//     max-width: 33.33%;
//     padding: 1rem;
//   }
// }

// @media only screen and (max-width: 1420px) {
//   .zard_gallery_tile {
//     flex: 50% 1;
//     max-width: 50%;
//     padding: 5px;
//     text-align: -webkit-center !important;
//   }
// }
// @media only screen and (max-width: 999px) {
//   .zard_gallery_tile {
//     flex: 100% 1;
//     max-width: 100%;
//     padding: 5px;
//     text-align: -webkit-center !important;
//   }
// }

// @media only screen and (max-width: 768px) {
//   .zard_gallery_tile {
//     flex: 50% 1 !important;
//     max-width: 50% !important;
//     padding: 5px !important;
//     text-align: -webkit-center !important;
//   }
 
// }

// @media only screen and (max-width: 499px) {
//   .zard_gallery_tile {
//     flex: 100% 1 !important;
//     max-width: 100% !important;
//     padding: 5px !important;
//     text-align: -webkit-center !important;
//   }
 
// }
