.modalOveride {
  // height: 500px;
  width: 80% !important;
 
}
.modalOveride {
  // height: 500px;
  > div {
    border-radius: 10px !important;
    > div> div:last-child {
      display: none;
    }
  }
  > div> div > button {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .modalOveride {
    min-width: 95% !important;
  }
}

@media only screen and (min-width: 769px) {
  .modalOveride {
    min-width: 800px !important;
  }
}

.modalOverideNotSelect {
  // height: 500px;
  // width: 80% !important;
  // min-width: 500px !important;
  > div {
    border-radius: 10px !important;
    > div:last-child {
      display: none;
    }
  }
  > div > button {
    display: none;
  }
}
.closeBtn {
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 30px;
}
.bottonBtn {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  width: 150px;
  height: 52px;
  border-radius: 5px;
  // background-color: #0f4c82;
  // color: #ffffff;
  // text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}
.bottonBtnNotActive {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  width: 150px;
  height: 52px;
  border-radius: 5px;
  // background-color: #ababab;
  // color: #717171;
  // text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}
.boxCreate {
  margin: 15px;
  margin-top: 10px;
}
.boxCreateInput {
  width: 100%;
  font-size: 1.25rem;
  border-radius: 5px;
  border-width: 1px;
  padding: 10px;
  border-color: transparent;
  background-color: #fff;
  border: 1px solid #e7edf0;
}

.switchOveride {
  background-color: rgba(0, 0, 0, 0.25) !important;
  > div:first-child {
    top: -1px !important;
    left: -1px !important;
    width: 23px !important;
    height: 23px !important;
  }
  > div::before {
    background-color: #ececec !important;
    border-radius: 50% !important;
  }
}

.switchOverideChecked {
  > div:first-child {
    top: -1px !important;
    // left: -1px !important;
    width: 23px !important;
    height: 23px !important;
  }
  > div::before {
    background-color: #ececec !important;
    border-radius: 50% !important;
  }
  background-color: #0f4c82 !important;
}

.directoryFiles {
  color: #93929c;
  font-size: 0.6875rem;
}
.itemgroup {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}
.itemFolders:hover {
  background-color: #f7f7f7;
}
.textItem {
  font-size: 1rem;
  color: #000000;
  margin-left: 20px;
}
.nextIcon {
  display: block;
  background-color: #0f4c82;
  border-radius: 3px;
  padding: 8px;
  width: 25px;
  float: right;
  text-align: center;
}
.nextIconNotActive {
  display: block;
  background-color: #a2a2a2;
  border-radius: 3px;
  padding: 8px;
  width: 25px;
  float: right;
  text-align: center;
}

.backIcon {
  cursor: pointer;
}

.symbolPath {
  margin-right: 5px;
  margin-left: 5px;
  color: #bebebe;
}
.boxPath {
  display: flex;
  font-size: 1.5625rem;
  margin-bottom: 45px;
}
.pathNotActive {
  color: #bebebe;
  cursor: pointer;
}
.pathActive {
  color: #000000;
}
.boxList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  grid-gap: 15px;
}
.boxListUserGroup {
  font-size: 1rem;
  margin-top: 10px;
  height: 310px;
  overflow-y: auto;
  overflow-x: hidden;
}

.boxListTitle {
  margin: 20px 0px;
  color: #000000;
  font-size: 1.1rem;
}

.textOverflowListFileText {
  // margin: auto;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  // font-size: 1rem;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}
.loadmore {
  margin: auto;
  // text-align: center;
}
.loadmoreBtn {
  background-color: #0f4c82;
  width: 170px;
  padding: 8px;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  text-align: center;
  margin: auto;
  cursor: pointer;
}

.itemuser {
  // display: inline-flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}
.boxListUser {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
  // grid-gap: 15px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 75px);
  grid-gap: 3rem 2rem;
}

.textItemUser {
  font-size: 0.9rem;
  color: #000000;
  /* margin-left: 20px; */
}
.selectedItemShareUser {
  width: 27px;
  height: 27px;
  background-color: white;
  position: absolute;
  right: -10px;
  border-radius: 100%;
}
.selectedItemShareGroup {
  width: 27px;
  height: 27px;
  background-color: white;
  position: absolute;
  right: -7px;
  border-radius: 100%;
  top: -7px;
}
.selectedItemShareBox {
  position: relative;
}
.boxTextAlert {
  margin: 15px;
  margin-top: 60px;
}

.boxListUserGroup::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.boxListUserGroup::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.boxListUserGroup:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}
