.selectFTableContainer {
    height: 35vh;
    overflow-y: scroll;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
  .rowWrapper {
    width: 100%;
    display: flex;
    .text {
        width: 15vw;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
    .input {
        width: 25vw;
      .searchInput {
        font-size: 14px;
        border-radius: 8px;
      }
    }
    .textArea {
        width: 40vw;
    }
  }
}
