.textMessage {
    position: relative;
    background-color: black;
}

.textMessage .copyIcon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 5;
}

.copy {
    cursor: pointer;
}