.closeBtnBox {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  top: 0px;
  right: -20px;
  position: absolute;
}

.boxdetail {
  width: 380px;
  min-height: 100vh;
  background-color: white;
  position: fixed;
  right: 0px;
  padding-top: 10px;
  /* bottom: 0px; */
  // min-height: 62px;
  top: 0px;
  z-index: 999;
  border-left: #e7e6e6 solid 2px;
}
.boxdetailCommunity {
  width: 380px;
  min-height: 100vh;
  background-color: white;
  position: fixed;
  right: 0px;
  padding-top: 10px;
  /* bottom: 0px; */
  // min-height: 62px;
  top: 52px;
  z-index: 999;
}
.boxdetail::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.boxdetail::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.boxdetail:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.textOverflowListFileText {
  // margin: auto;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  // font-size: 1rem;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.headerBoxDetail {
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
  // border-bottom: 1px #bebebe solid;
  // border-top: 1px #bebebe solid;
  align-self: center;
  align-content: center;
}
.lineBoxTopBottom {
  border-bottom: 1px #bebebe solid;
  border-top: 1px #bebebe solid;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-top: 30px;
}

.boxIconText {
  margin: auto;
  margin-left: 5px;
}
.rowDetail {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.textHead {
  font-weight: bold;
}
.itemuser {
  // display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 145px;
  border-radius: 5px;
  padding: 3px;
  margin: 3px;
  padding-left: 0px;
  padding-right: 30px;
}
.verticalCenter {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.line {
  border-left: 1px solid #bebebe;
  margin-left: 15px;
  margin-right: 15px;
}
.classBoxOveride {
  width: 40px !important;
  height: 40px !important;
}

.linkShareBackground {
  text-align: -webkit-center;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  width: 44px;
  height: 44px;
  background-position: center;
}
.linkShare {
  color: #000000;
  align-self: center;
  width: 44px;
  height: 44px;
  background-color: #f4f4f4;
  border-radius: 50%;
}

.verticalCenter {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.fileCardBox {
  width: 100%;
  height: 80px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  // min-width: 320px;
}
.textOverflowListFileText {
  // margin: auto;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: 0.7rem;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.itemFile {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.boxProgressBarAll {
  // font-size: 1rem;
  > div {
    position: relative;
    width: 100%;
    display: flex;
    font-size: 0.875rem;
    align-content: center;
    align-items: center;
    height: 50px;
    > div {
      margin-right: unset !important;
      padding-right: unset !important;
      height: 23px !important;
      > div {
        background-color: #d4d4d4;
        > div {
          height: 25px !important;
          background: #0f4c82 !important;
        }
      }
    }
    > span {
      font-size: 1rem;
      font-weight: bold;
      color: #0f4c82 !important;
      position: absolute;
      display: flex !important;
      width: 100% !important;
      justify-content: center !important;
    }
  }
}
.boxProgressBarAllMore50 {
  // font-size: 1rem;
  > div {
    position: relative;
    width: 100%;
    display: flex;
    font-size: 0.875rem;
    align-content: center;
    align-items: center;
    height: 50px;
    > div {
      margin-right: unset !important;
      padding-right: unset !important;
      height: 23px !important;
      > div {
        background-color: #d4d4d4;
        > div {
          height: 25px !important;
          background: #0f4c82 !important;
        }
      }
    }
    > span {
      font-size: 1rem;
      font-weight: bold;
      color: white!important;
      position: absolute;
      display: flex !important;
      width: 100% !important;
      justify-content: center !important;
    }
  }
}
.boxProgressBar {
  // font-size: 1rem;
  > div {
    position: relative;
    width: 100%;
    display: flex;
    font-size: 0.875rem;
    align-content: center;
    align-items: center;
    > div {
      margin-right: unset !important;
      padding-right: unset !important;
      > div {
        background-color: #d4d4d4;
      }
      > span {
        display: none!important;
      }
    }
    > span {
      // position: absolute;
      // right: 297px;
      // top: 20px;
      font-size: 1rem!important;
      font-weight: bold!important;
      color: #0f4c82!important;
      // display: none;
    }
  }
}
.closeItemBtn {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  top: 0px;
  right: 0px;
  position: absolute;
}

.itemFileMain {
  width: 100%;
  align-items: center;
  padding: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.modalOveride {
 
  > div:not(:first-child):not(:last-child) {
    // height: 100% !important;
    // max-height: 700px !important;
    height: 270px !important;
  }
  
}

.modalOveride {
  // height: 500px;
  > div {
    border-radius: 10px !important;
    > div> div:last-child {
      display: none;
    }
  }
  > div> div > button {
    display: none;
  }
}
.closeBtn {
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 37px;
  right: 39px;
}
.bottonBtn {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  margin-right: 10px;
  width: 100px;
  height: 45px;
  border-radius: 5px;
  background-color: #0f4c82;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}
.boxCreate {
  margin: 15px;
  margin-top: 40px;
  text-align: center;
}
.boxConfirmBtn {
  justify-content: center;
}
.boxConfirmBtn > button {
  margin-left: 60px;
  margin-right: 60px;
}
.boxCreateInput {
  width: 100%;
  font-size: 1.25rem;
  border-radius: 5px;
  border-width: 1px;
  padding: 10px;
  border-color: transparent;
  background-color: #f0f0f0;
}

.switchOveride {
  background-color: rgba(0, 0, 0, 0.25) !important;
  > div:first-child {
    top: -1px !important;
    left: -1px !important;
    width: 23px !important;
    height: 23px !important;
  }
  > div::before {
    background-color: #ececec !important;
    border-radius: 50% !important;
  }
}

.switchOverideChecked {
  > div:first-child {
    top: -1px !important;
    // left: -1px !important;
    width: 23px !important;
    height: 23px !important;
  }
  > div::before {
    background-color: #ececec !important;
    border-radius: 50% !important;
  }
  background-color: #0f4c82 !important;
}

.directoryFiles {
  color: #93929c;
  font-size: 0.6875rem;
}

.bottonBtnCancel {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  margin-right: 10px;
  width: 100px;
  height: 45px;
  border-radius: 5px;
  background-color: white;
  color: #0f4c82;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  // border-color: unset !important;
  // border-width: unset !important;
  // border-style: unset !important;
  border: 1px solid #0f4c82;
}

.bottonBtnOk {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  // margin-right: 10px;
  width: 100px;
  height: 45px;
  border-radius: 5px;
  background-color: #0f4c82;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  // border-color: unset !important;
  // border-width: unset !important;
  // border-style: unset !important;
  border: 1px solid #0f4c82;
}

.canceledText {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  top: 0px;
  right: 31px;
  position: absolute;
}

@media only screen and (max-width: 499px) {
  .boxdetail {
    width: 100%;
  }
}

@media only screen and (min-width: 500px) {
  .boxdetail {
    width: 380px;
  }
}

@media only screen and (max-width: 1100px) {
  .boxdetailCommunity {
    top: 135px;
  }
}

.scrollableRow {
  height: calc(100vh - 250px);
  overflow-y: auto;
}