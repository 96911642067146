.cardContainer {
    height: 100%;
    border-radius: 16px;
    background-color: #ffffff;
    margin: 16px 16px;
}

.planBox {
    border: 1px solid #DCDCDC;
    border-radius: 8px;
    padding: 12px 16px;
    transition: all 0.2s ease-in-out;

    &:hover {
        border-color: #919EAB;
        background-color: #F4F6F8;
    }
}

// Plan details dialog
.dialogWrapper {
    .dialogContent {
        max-height: 90vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding: 24px;
        min-width: 520px;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: #DFE3E8;
            border-radius: 3px;
        }
    }

    .dialogHeader {
        flex-shrink: 0;
        text-align: center;

        h4 {
            color: #212B36;
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 8px;
        }

        h6 {
            color: #637381;
            font-size: 14px;
        }
    }

    .priceContainer {
        flex-shrink: 0;
        text-align: center;
        margin: 16px 0;

        .priceAmount {
            color: #212B36;
            font-size: 32px;
            font-weight: 700;
        }

        .pricePeriod {
            color: #637381;
            font-size: 14px;
            margin-left: 4px;
        }
    }

    .featureList {
        padding: 24px;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 12px;
        backdrop-filter: blur(8px);
    }

    .featureItem {
        margin-bottom: 0px;

        &:last-child {
            margin-bottom: 0;

            .featureDivider {
                display: none;
            }
        }
    }

    .featureContent {
        display: flex;
        align-items: center;
        padding: 6px 0; // Reduced from 12px
        gap: 8px; // Reduced from 16px
    }

    .checkIcon {
        color: #4CAF50;
        flex-shrink: 0;
    }

    .featureDetails {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .featureLabel {
        font-weight: 500;
        color: #2C3E50;
    }

    .featureValue {
        font-weight: 600;
        color: #1976D2;
    }

    .featureDivider {
        height: 1px;
        background: rgba(0, 0, 0, 0.08);
        margin-top: 8px;
    }

    .actionButtons {
        flex-shrink: 0;
        margin-top: auto;
        padding-top: 16px;
        display: flex;
        justify-content: flex-end;
        gap: 12px;

        button {
            min-width: 120px;
            height: 40px;
            border-radius: 8px;
            font-weight: 600;
            font-size: 14px;

            &.cancelBtn {
                color: #637381;
                background: transparent;
                border: 1px solid #DFE3E8;

                &:hover {
                    background: #F4F6F8;
                }
            }

            &.upgradeBtn {
                color: #FFFFFF;
                background: #003da7;

                &:hover {
                    background: #001678;
                }
            }
        }
    }
}

// Contract dialog
.enterpriseDialog {
    :global(.MuiDialog-paper) {
        width: 520px;
        max-width: 520px;
    }

    .dialogContainer {
        min-height: 380px;
        display: grid;
        grid-template-columns: 1fr;
    }

    .heroSection {
        background: linear-gradient(45deg, #000428, #004e92);
        padding: 32px;
        position: relative;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
    }

    .heroContent {
        position: relative;
        z-index: 1;
        color: white;
    }

    .badge {
        display: inline-block;
        padding: 4px 10px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 16px;
        font-size: 13px;
        margin-bottom: 16px;
    }

    .heroTitle {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 8px;
    }

    .heroSubtitle {
        font-size: 16px;
        opacity: 0.9;
    }

    .contactSection {
        padding: 32px;
        background: white;
    }

    .sectionTitle {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 24px;
        color: #0f172a;
    }

    .contactCards {
        display: grid;
        gap: 12px;
        margin-bottom: 24px;
    }

    .contactCard {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 16px;
        background: #f8fafc;
        border-radius: 12px;
        transition: all 0.2s ease;

        &:hover {
            background: #f1f5f9;
            transform: translateY(-2px);
        }
    }

    .iconBox {
        width: 44px;
        height: 44px;
        background: white;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

        .iconInner {
            font-size: 20px;
        }
    }

    .cardLabel {
        color: #64748b;
        font-size: 13px;
        margin-bottom: 2px;
    }

    .cardValue {
        color: #0f172a;
        font-size: 15px;
        font-weight: 500;
    }

    .actionButton {
        width: 100%;
        height: 44px;
        background: #0f172a;
        color: white;
        font-size: 15px;
        border-radius: 10px;
        text-transform: none;

        &:hover {
            background: #1e293b;
        }
    }
}