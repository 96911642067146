.genTableSelect {
    font-size: 12px;
    height: 30px;
    text-align: start;
  }
  .tdSetCenter {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .addBtn {
    height: 60%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    padding: 8px;
    color: #1c87f2;
  }
  
  .actionIconWrapper {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    border: solid 1px #e9e9e9;
  
    .actionIcon {
      fill: #ababab;
    }
  }
  .actionIconWrapper:hover {
    border-color: #1c87f2;
  
    .actionIcon {
      fill: #1c87f2;
    }
  }
  