// @font-face {
//   font-family: 'Prompt';
//   src: url('../../../fonts/Prompt-Regular.ttf') format('truetype');
//   font-weight: 400;
// }
// @font-face {
//   font-family: 'Prompt';
//   src: url('../../../fonts/Prompt-Medium.ttf') format('truetype');
//   font-weight: 500;
// }

.boxCreate {
  margin: 15px;
  margin-top: 10px;
}

.closeBtn {
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.modalOveride {
  // height: 500px;
  > div {
    border-radius: 10px !important;
    > div> div:last-child {
      display: none;
    }
  }
  > div> div > button {
    display: none;
  }
}

.selectShareLink{
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  
  // gap: 10px;

  padding: 15px 10px;
  height: 72px;
  margin: 24px;

  border: 1px solid #F0F0F0;
  border-radius: 10px;
}

.spanTextSetting{
  width: 108px;
  height: 21px;
  margin: 0 24px 24px;

  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #181818;
}

.spanTextBlocDownload{
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 21px;
  align-items: center;

  color: #000000;
}
// .boxCreateInput:focus {
//   border: 1px solid #F0F0F0;
// }
.boxSelect {
  font-size: 1.25rem;
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  // padding: 10px 20px;
  background: #FFFFFF;
  // margin:0 24px 24px;

  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;


  letter-spacing: -0.019em;

  color: #BEBEBE;
  
  > div > div {
    // padding: 16px 20px;
    min-height: 4.5rem;
  }
}

.boxSearch {
  // font-size: 1.25rem;
  // border-radius: 10px;
  // border: 1px solid #F0F0F0;
  // // padding: 10px 20px;
  // background: #FFFFFF;
  // // margin:0 24px 24px;

  // font-family: 'Prompt';
  // font-style: normal;
  // font-weight: 400;
  // font-size: 14px;
  // line-height: 150%;


  // letter-spacing: -0.019em;

  // color: #BEBEBE;
  
  // > div > div {
  //   padding: 16px 20px;
  // }
}

.modalCopyLink > div >div{
  // max-width: 551px;
}

.customScroollbar{
  /* width */
  ::-webkit-scrollbar {
      width: 10px;
  }

  /* Track 
  ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
  }*/
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: #E8ECEF; 
      border-radius: 10px;
  }
}


.imgWorld {
  width: 24px;
  height: 24px;
  position: relative;
  top: 20%;
}

.imgBox {
  align-self: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align-last: center;
  text-align: center;
}