.table {
  :global {
    .ant-table {
      border: none;
    }

    .ant-table-container {
      border: none !important;
    }

    .ant-table-thead > tr > th {
      background-color: #f8f8f8;
      color: #393939;
      font-size: 14px;
      padding: 12px 16px;
    }
  }
}
.btnActive {
  background-color: #1c87f2;
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 1.5rem;
  border: none;
  cursor: pointer;
}
