/* CardStyles.css */

.cardContainer {
  width: 240px;
  height: 240px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  cursor: pointer;

  >div {
    padding: 10px !important;
  }
}

.cardContent {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  padding-bottom: 16px;
  display: inline-flex;
}

.cardImage {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.cardTitle {
  color: #19202D;
  font-size: 13px;
  font-weight: 500;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatarContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.avatarImage {
  width: 30px;
  height: 30px;
}

.userInfo {
  display: flex;
  flex-direction: column;
}

.userEmail {
  color: #19202D;
  font-size: 12px;
  font-weight: 400;
}

.uploadDate {
  color: #9397A0;
  font-size: 10px;
  font-weight: 300;
}

.iconContainer {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background: #F4F9FE;
}