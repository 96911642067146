.boxIcon {
    background-color: #0f4c82;
  }
  .boxIconCenter {
    position: absolute;
    top: 40px;
    left: 40px;
    // margin-right: -50%;
    // transform: translate(-50%, -50%);
  }
  .btnLogin {
    border-radius: 5px;
    margin-top: 10px;
    background-color: #868686;
    border-color: #868686;
    float: right;
  }
  .btnLogin:hover,
  .btnLogin:focus {
    color: #fff;
    background-color: #9c9c9c;
    border-color: #9c9c9c;
  }
  .logoLoginApp {
    height: 80px;
    width: 80px;
  }
  .boxLogin {
    width: auto;
    margin: auto;
    padding: 50px;
  }
  .titleLogin {
  
  }
  .textWellcome {
    font-size: 1.5rem;
    color: rgb(0, 0, 0);
    margin-top: 40px;
  }
  .textDetail {
    font-size: 1rem;
    // color: rgb(0, 0, 0);
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 300px;
  }
  
  .inputBox {
    border-radius: 5px;
    height: 40px;
    width: 100%;
    input {
      // font-size: 18px;
    }
  }
  .inputBoxPassword {
    margin-top: 5px;
    border-radius: 5px;
    height: 40px;
    padding: 0;
    width: 100%;
    input {
      padding: 4px 11px !important;
      // border-radius: 5px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    span {
      // display: none !important;
      padding: 0 6px 0 4px;
      // background-color: red;
    }
    background-color: rgb(255, 255, 255);
  }
  .signinBtn {
    height: 50px;
    width: 130px;
    border-radius: 50px;
    font-size: 1.2rem;
    background-color: #0f4c82 !important;
    border-color: #0f4c82 !important;
  }
  .socialIcon {
    margin-left: 10px;
    height: 45px;
    width: 45px;
    cursor: pointer;
  }
  .linkText {
    color: #428dff;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    text-align: end;
  }
  .overideCheckBox >
    span{
    top: -13px !important;
    left: 1px  !important;
  }
  .overideCheckBox {
    span > span {
      background-color: white !important;
      border-color: #0f4c82 !important;
      // top: 0px;
    }
  }
  .overideCheckBoxCheck {
    span > span {
      background-color: #0f4c82 !important;
      border-color: #0f4c82 !important;
      // top: -11px;
    }
  }
  /*
  .overideCheckBox {
    input:checked + span:before {
      background: #0f4c82 !important;
      border: none !important;
    }
  }
  */
  
  .btnMainBox {
    align-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  
  .btnMainBox2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  
  .boxNameDetail {
    margin-left: 20px;
    align-self: center;
  }
  .BErrorMessage {
    display: flex;
    justify-content: center;
    :global .error-message {
      // width: 60%;
      white-space: nowrap;
      text-align: center;
    }
  }
  
  .boxLoginInfoWidth {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .contentLogin {
    height: 100vh;
    font-size: 20px;
  }
  
  @media only screen and (max-width: 599px) {
    /* For tablets: */
    .boxIconWidth {
      // width: 25%;
      width: 210px;
      display: none;
    }
    .boxLoginInfoWidth {
      // width: 75%;
      width: 100vw;
    }
    .contentLogin {
      min-width: 100vw;
      // justify-content: center;
    }
  }
  @media only screen and (min-width: 600px) {
    /* For tablets: */
    .boxIconWidth {
      width: 25%;
      // width: 210px;
    }
    .boxLoginInfoWidth {
      width: 75%;
      // width: 600px;
    }
    .contentLogin {
      min-width: 100vw;
    }
  }
  @media only screen and (min-width: 900px) {
    /* For desktop: */
    .boxIconWidth {
      width: 50%;
      // min-width: 210px;
    }
    .boxLoginInfoWidth {
      width: 50%;
      // min-width: 600px;
    }
    .contentLogin {
      min-width: 810px;
    }
  }
  
  .iframLoginGG{
    // margin: 0 -10px;
    width: 100%;
    height: 56px;
    overflow: hidden;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    // padding-top: 10px;
    margin-bottom: 16px;
  }
  .iframLoginFB{
    // margin: 0 -10px;
    width: 100%;
    height: 56px;
    overflow: hidden;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    margin-bottom: 16px;
  }
  .iframLoginAZ{
    // margin: 0 -10px;
    width: 100%;
    height: 56px;
    overflow: hidden;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
  }
  
  .btn {
    border-radius: 100px;
    height: 50px;
    margin-bottom: 16px;
  
    img {
      position: absolute;
      left: 24px;
    }
  }



  
.logoLoginGG {
  width: 36px;
}
.logoLoginAZ {
  width: 36px;
}
.boxSocialLogin {
  display: flex;
  // background-color: white;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}
.boxSocialLogin:hover {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}
.boxSocialLoginGG {
  background-color: white;
}
.boxSocialLoginFB {
  background-color: #1877f2;
}
.boxSocialLoginAZ {
  background-color: white;
}
.marginBottom16 {
  margin-bottom: 16px;
}
.textLogin {
  align-content: center;
  margin-left: 25px;
  font-size: 16px;
  font-weight: bold;
}
.textLoginGG {
  color: #5b5b5b;
}
.textLoginFB {
  color: white;
}

.iframeLogin {
  position: absolute;
  z-index: -1;
}