.dialog {
  .paper {
    border-radius: 24px;
    background: linear-gradient(145deg, #ffffff, #f9fafb);
    box-shadow: 0 12px 48px rgba(0, 0, 0, 0.12);
    overflow: hidden;
  }

  .header {
    padding: 24px 32px 8px;
    
    .title {
      font-size: 28px;
      font-weight: 700;
      background: linear-gradient(90deg, #2563eb, #4f46e5);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .content {
    padding: 16px 32px;

    .message {
      font-size: 16px;
      line-height: 1.7;
      color: #374151;

      .planName {
        display: inline-block;
        font-weight: 600;
        color: #2563eb;
        padding: 2px 8px;
        margin: 0 4px;
        background: rgba(37, 99, 235, 0.1);
        border-radius: 6px;
      }
    }
  }

  .actions {
    padding: 24px 32px;
    gap: 16px;
    background: #f8fafc;

    .cancelBtn {
      padding: 12px 32px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 500;
      text-transform: none;
      border: 2px solid #e2e8f0;
      color: #64748b;
      transition: all 0.2s ease;

      &:hover {
        background: #f1f5f9;
        border-color: #cbd5e1;
      }
    }

    .actionBtn {
      padding: 12px 32px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 500;
      text-transform: none;
      background: linear-gradient(90deg, #2563eb, #4f46e5);
      color: white;
      transition: all 0.2s ease;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 8px 24px rgba(37, 99, 235, 0.25);
      }
    }
  }
}
