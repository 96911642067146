.createTitleModal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    font-size: 16px;
    border-bottom: solid 1px #e9e9e9;
    padding-bottom: 16px;
  
    .title {
      display: flex;
      margin-bottom: 8px;
    }
    .actionTool {
      display: flex;
      justify-content: flex-start;
      gap: 24px;
  
      .actionIconWrapper {
        height: 28px;
        width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 6px;
        border: solid 1px #e9e9e9;
  
        .actionIcon {
          fill: #ababab;
        }
      }
      .actionIconWrapper:hover {
        border-color: #1c87f2;
  
        .actionIcon {
          fill: #1c87f2;
        }
      }
    }
  }
  .createBoEmptyItem {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .createContentModal {
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    // align-items: center;
  }
  .createFooterModal {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
  
    .cancelBtn {
      height: 100%;
      width: 150px;
      border-radius: 4px;
      padding: 4px 0;
    }
    .confirmBtn {
      height: 100%;
      width: 150px;
      border: 0;
      border-radius: 4px;
      padding: 4px 0;
      background: #1c87f2;
      color: #ffffff;
      cursor: pointer;
    }
  }