.iconMenu {
  font-size: 2rem;
  color: rgb(124, 124, 124);
}

.closeBtn {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  // position: absolute;
  top: 37px;
  right: 39px;
  float: right;
}
  
.menu {
  box-sizing: border-box;
  // margin:15px;
  border: 0px solid #F5F5F5;
  // box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  // border-radius: 10px;
  // min-width: 150px;
  background: none;
}
.itemMenu {
  // margin-top: 5px;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  padding: 5px;
  
  // padding-left: 8px;
  // padding-right: 8px;
}

.itemsMenu {
  // cursor: pointer;
  user-select: none;
  border-radius: 5px;;
}

.itemGroup {
  border-radius: 10px;
}

.itemSubMenu {
  > div {
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    padding: 5px;

    > span:last-child {
      display: none;
    }
  }
}