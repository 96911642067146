.addIconColor {
    background-color: #ffffff33;
    color: white;
    // padding: 5px;
    text-align: center;
    width: 45px;
    height: 45px;
    border-radius: 5px;
  }
  .colorAudioType {
    // transform: translate(0%, 50%);
    color: #0f4c82;
  }
  .colorAudioTypeActiveMove {
    // transform: translate(0%, 50%);
    color: #0f4c8240;
  }
  .colorVideoType {
    // transform: translate(0%, 50%);
    color: #458be7;
  }
  .colorVideoTypeMedia {
    color: #458be7;
    background-color: #cccccc;
    text-align-last: center;
    border: 2px solid white;
    width: 100%;
    height: 100%;
    text-align: center;
    // transform: translate(0%, 50%);
  }
  .colorVideoTypeActive {
    // transform: translate(0%, 50%);
    color: #458be740;
  }
  .colorDocType {
    // transform: translate(0%, 35%);
    color: #5351fb;
  }
  .colorDocTypeActiveMove {
    // transform: translate(0%, 35%);
    color: #5351fb40;
  }
  .colorPicType {
    // transform: translate(0%, 50%);
    color: #c388a6;
    // border: 1px #e6e6e6 solid;
    border-radius: 8px;
  }
  .colorPicTypeActiveMove {
    // color: #c388a640;
    // transform: translate(0%, 50%);
  }
  .colorOtherType {
    color: #be63f9;
    // transform: translate(0%, 50%);
  }
  .colorOtherTypeActiveMove {
    color: #be63f940;
    // transform: translate(0%, 50%);
  }
  .colorBinType {
    color: white;
    // transform: translate(0%, 50%);
  }
  .colorDirType {
    color: white;
    // transform: translate(0%, 50%);
  }
  .colorDirActiveMove {
    color: #ffff;
    // transform: translate(0%, 50%);
  }
  .colorBinTypeActiveMove {
    color: #ffff;
    // transform: translate(0%, 50%);
  }
  
  .colorZipType {
    color: #6dceb7;
    // transform: translate(0%, 50%);
  }
  .colorZipActiveMove {
    color: #6dceb740;
    // transform: translate(0%, 50%);
  }

  .eyeViewVideo {
    position: absolute;
    text-align: center;
    top: 7px;
    left: 18px;
    font-size: 1.125rem;
    color: #444444 !important;
  }
  .eyeViewImg {
    position: absolute;
    text-align: center;
    top: 27px;
    left: 11px;
    font-size: 1.125rem;
    color: #444444 !important;
  }

  .playVideoIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    // font-size: 1.25rem;
    color: white;
  }

  .boxPlayer {
    background-color: #5a5a5a;
    border-radius: 8px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 10px;
    padding-right: 7px;
    text-align: center;
    vertical-align: middle;
  }
  .centercss{
    text-align: -webkit-center;
}

.flexcss{
  display: flex;
}