.customInfinitscroll {
  overflow: visible !important;
}

.customInfinitscroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.customInfinitscroll::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.customInfinitscroll:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.imgFontTypeMedia {
  width: 100%;
  height: 80px;
  min-height: 80px;
  /* border-radius: 50%; */
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e6e6e6;
  div {
    border: 1px solid white;
    width: 100%;
    height: 80px;
    &:only-child {
      width: 100%;
      height: 80px;
      min-height: 80px;
    }
  }
}
.imgFontTypeMedia > div > div {
  background-position: center;
}
// .playVideoIcon {
//   position: absolute;
//   top: 93px;
//   left: 140px;
// }
.playVideoIcon {
  position: absolute;
  top: 50%;
  left: 52%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  // font-size: 1.25rem;
  color: white;
}
.playVideoIconImg {
  background: url("../../../../../assets/images/PlayVideo.png");
  background-size: cover;
  width: 43px;
  height: 37px;
}
.playVideoIconImg:hover {
  background: url("../../../../../assets/images/PlayVideoGray.png");
  background-size: cover;
  width: 43px;
  height: 37px;
}
.boxMediaItem:hover {
  .playVideoIconImg {
    background: url("../../../../../assets/images/PlayVideoGray.png");
    background-size: cover;
    width: 43px;
    height: 37px;
  }
}
.modalOveride {
  width: auto !important;
  // height: 100% !important;
  // background-color: #00000065 !important;
  text-align: -webkit-center !important;
  > div:nth-child(2) {
    background: #000000e0;
    padding-top: 34px;
    border-radius: 24px;
    // border: 1px solid #535353;
    -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);

    button {
      color: #FFFFFF;
      > span {
        line-height: 50px;
      }

      svg {
        font-size: 22px;
      }
    }
  }
}
// @media only screen and (max-width: 768px) {
//   .modalOveride {
//     min-width: 95% !important;
//   }
// }

// @media only screen and (min-width: 769px) {
//   .modalOveride {
//     min-width: 68px !important;
//   }
// }

.reactPlayer {
  border: 1px solid #ffffff1f;
}

.playerWrapper {
  width: auto !important; // Reset width
  height: auto !important; // Reset height
}
.ReactPlayerOveride > div {
  text-align: center;
  width: fit-content !important;
  height: fit-content !important;
}
.ReactPlayerOveride {
  place-content: center;
}
.textEmpty {
  text-align: center;
  color: #a0a0a0;
  font-size: 0.8rem;
}

.collect_symbol {
  width: 15px;
  height: 15px;
  background-size: cover;
  z-index: 1;
  top: 14px;
  left: 13px;
  position: absolute;
}
.box_symbol {
  right: 0;
  z-index: 1;
  position: absolute;
  top: 50%;
  background-color: #00000080;
  padding: 20px;
  border-radius: 100%;
  border: solid 2px white;
}

.box_symbol_selected {
  right: 0;
  z-index: 1;
  position: absolute;
  top: 50%;
  padding: 20px;
  background-color: #428dff;
  border-radius: 100%;
  border: solid 2px#428DFF;
}

// .boxMediaItem {
//   > div {
//     > div {
//       max-width: 290px !important;
//     }
//   }
// }
// @media only screen and (max-width: 768px) {
//   .boxMediaItem {
//     > div {
//       > div {
//         max-width: 290px !important;
//       }
//     }
//   }
 
// }

@media only screen and (max-width: 499px) {
  .boxMediaItem {
    > div {
      > div {
        max-width: 100% !important;
      }
    }
  }
  .box_symbol_selected{
    position: absolute !important;
    // right: 5px !important;
  }
  .box_symbol{
    position: absolute !important;
    // right: 5px !important;
  }
 
}
