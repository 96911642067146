.billingContainer {
  // padding: 24px;

  :global {
    .ant-tabs {
      .ant-tabs-nav {
        margin-bottom: 32px;
        padding: 0;
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

        &::before {
          display: none;
        }

        .ant-tabs-tab {
          padding: 12px 20px;
          margin: 0;
          font-size: 14px;
          transition: all 0.2s ease;
          border-radius: 12px;
          color: #475467;

          &:hover {
            color: #4361ee;
          }

          &.ant-tabs-tab-active {
            background: #f9fafb;

            .ant-tabs-tab-btn {
              color: #4361ee;
              font-weight: 500;
            }
          }

          &+.ant-tabs-tab {
            margin-left: 4px;
          }
        }

        .ant-tabs-ink-bar {
          display: none;
        }
      }

      .ant-tabs-content {
        padding: 0;
        background: transparent;
      }
    }

    .ant-card {
      border-radius: 16px;
      border: 1px solid #e5e9f2;
      box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);

      .ant-card-body {
        padding: 24px;
      }
    }

    .ant-space {
      width: 100%;
    }
  }
}

.cardWrapper {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  transition: all 0.2s ease;
  border: 1px solid #e5e9f2;

  &:hover {
    box-shadow: 0 4px 8px rgba(16, 24, 40, 0.1), 0 2px 4px rgba(16, 24, 40, 0.06);
  }
}

.header {
  padding: 0;
  margin-bottom: 32px;

  :global {
    .ant-typography {
      margin: 0;
      font-weight: 600;
      font-size: clamp(24px, 3vw, 30px);
      color: #1a1f36;
      letter-spacing: -0.02em;
    }
  }
}

.planCard {
  border: 1px solid #e5e9f2;
  border-radius: 16px;
  padding: 24px;
  height: 100%;
  transition: all 0.2s ease;
  background: #ffffff;
  display: flex;
  flex-direction: column;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 24px rgba(16, 24, 40, 0.06);
    border-color: #4361ee;
  }
}

.plansGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  padding: 0;
  margin: 0 auto;
  max-width: 1200px;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

// Responsive styles
@media (max-width: 768px) {
  .billingContainer {
    padding: 16px;

    :global {
      .ant-tabs {
        .ant-tabs-nav {
          margin-bottom: 24px;

          .ant-tabs-tab {
            padding: 10px 16px;
            font-size: 13px;
          }
        }
      }

      .ant-card {
        .ant-card-body {
          padding: 16px;
        }
      }
    }
  }

  .header {
    margin-bottom: 24px;
  }

  .plansGrid {
    gap: 16px;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .billingContainer {
    padding: 12px;
  }

  .planCard {
    padding: 16px;
  }
}

// Tab content animations
:global {
  .ant-tabs-content {
    .ant-tabs-tabpane {
      animation: slideUp 0.3s ease;
    }
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(8px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}