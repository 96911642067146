.genPageContainer {
  width: 100%;
  height: 75vh;
  margin-top: 15px;
  .genPageTitle {
    margin: 16px 0;
    font-size: 18px;
  }
}
.footerButton {
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: flex-start;
  gap: 12px;

  .cancelBtn {
    height: 100%;
    width: 150px;
    border-radius: 4px;
    padding: 4px 0;
  }
  .confirmBtn {
    height: 100%;
    width: 150px;
    border: 0;
    border-radius: 4px;
    padding: 4px 0;
    background: #1c87f2;
    color: #ffffff;
    cursor: pointer;
  }
}
