.ant-modal, .ant-modal-content {
  height: 100vh;
  width: 100vw;
  margin: 0;
  top: 0;
 }
 .ant-modal-body {
  height: calc(100vh - 110px);
 }

.createTitleModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  font-size: 16px;
  border-bottom: solid 1px #e9e9e9;
  padding-bottom: 16px;

  .title {
    display: flex;
    margin-bottom: 8px;
  }
  .actionTool {
    display: flex;
    justify-content: flex-start;
    gap: 24px;

    .actionIconWrapper {
      height: 28px;
      width: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 6px;
      border: solid 1px #e9e9e9;

      .actionIcon {
        fill: #ababab;
      }
    }
    .actionIconWrapper:hover {
      border-color: #1c87f2;

      .actionIcon {
        fill: #1c87f2;
      }
    }
  }
}
.createBoEmptyItem {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.createContentModal {
  width: 100%;
  max-height: 45vh;
  min-height: 45vh;
  display: flex;
  justify-content: center;
  overflow: auto;
  // align-items: center;
}

.createContentModalExpand {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  height: 78vh;
  // align-items: center;
}
.createFooterModal {
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: flex-start;
  gap: 12px;

  .cancelBtn {
    height: 100%;
    width: 150px;
    border-radius: 4px;
    padding: 4px 0;
  }
  .confirmBtn {
    height: 100%;
    width: 150px;
    border: 0;
    border-radius: 4px;
    padding: 4px 0;
    background: #1c87f2;
    color: #ffffff;
    cursor: pointer;
  }
}

//Create Business Object
.createBusinessObjContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .boTableMappingContainer {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 12px;

    .boContainer {
      width: 45%;
      height: 38vh;
      border: solid 1px #e9e9e9;
      border-radius: 6px;
    }
    .tableContainer {
      width: 45%;
      height: 38vh;
      border: solid 1px #e9e9e9;
      border-radius: 6px;
    }
  }
  .genTableContainer {
    width: 25vw;
    height: 100%;
    padding: 0 10px;
    // border: solid 1px #e9e9e9;
    .genTableBtn {
      width: 100%;
      height: 40px;
      border-color: #1c87f2;
      color: #1c87f2;
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .genTableBtnDisabled {
      width: 100%;
      height: 40px;
      border-color: #d9d9d9;
      color:rgba(0, 0, 0, 0.25);
      cursor: none;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .genTableBtn:hover {
      background: #1c87f2;
      color: #ffffff;
    }

    .selectWrapper {
      margin-top: 20px;
      .titleWrapper {
        display: flex;
        align-items: center;
        .textHeader {
          font-size: 12px;
          margin: 0;
        }
        .textImportant {
          color: red;
          font-size: 14px;
        }
      }
      .actionWrapper {
        display: flex;
        align-items: center;
        gap: 6px;
        .genTableSelect {
          font-size: 12px;
          height: 40px;
        }
        .actionIconWrapper {
          height: 28px;
          width: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 6px;
          border: solid 1px #e9e9e9;

          .actionIcon {
            fill: #ababab;
          }
        }
        .actionIconWrapper:hover {
          border-color: #1c87f2;

          .actionIcon {
            fill: #1c87f2;
          }
        }
      }
    }
  }
}

//Business Object Config
.boConfigContainer {
  width: 100%;

  .boConfigHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: solid 1px #e9e9e9;
    margin: 12px 5px 5px 5px;
    padding-bottom: 5px;

    .boConfigHeaderItem {
      margin: 0;
      max-width: 150px;
    }
  }

  .boConfigContent {
    width: 100%;
    height: 35vh;
    overflow-y: scroll;
    scrollbar-width: none;

    .boConfigContentRow {
      height: 50px;
      display: flex;
      justify-content: space-between;
      padding: 5px;
      .boConfigContentCol {
        display: flex;
        justify-content: center;
        max-width: 150px;
      }
    }
  }
}

.messageNotify {
  padding-left: 15px;
  font-weight: 600;
}
.descriptionNotify {
  padding-left: 15px;
  font-size: 12px;
}
.iconNotify {
  display: flex;
  align-items: center;
  height: 54px;
  width: 54px;
  margin-right: 10px;
  margin-left: -10px;
}
.iconSuccessNotify {
  font-size: 35px;
}
