.textInputContainer {
  display: flex;
  align-items: center;

  .textHeader {
    font-size: 14px;
    margin: 0;
  }

  .textImportant {
    color: red;
  }
}

.footerContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  .btnCancel {
    width: 40%;
    border: 0;
    border-radius: 4px;
    padding: 4px 0;
    cursor: pointer;
  }
  .btnCreate {
    width: 40%;
    border: 0;
    border-radius: 4px;
    padding: 4px 0;
    background: #1c87f2;
    color: #ffffff;
    cursor: pointer;
  }

  .btnCreate:disabled {
    background-color: #ababab; /* กำหนดสีพื้นหลังเมื่อปุ่มถูกปิดใช้งาน */
    color: black; /* กำหนดสีข้อความเมื่อปุ่มถูกปิดใช้งาน */
    cursor: not-allowed;
  }
}

.circularImage {
  //   display: flex;
  width: 25px; /* ปรับขนาดตามที่ต้องการ */
  height: 25px; /* ปรับขนาดตามที่ต้องการ */
  border-radius: 50%; /* ทำให้เป็นรูปวงกลม */
  overflow: hidden; /* ป้องกันรูปภาพเกินขอบ */
}

.circularImage img {
  width: 100%; /* ทำให้รูปภาพเต็มพื้นที่ */
  height: 100%; /* ทำให้รูปภาพเต็มพื้นที่ */
  object-fit: cover; /* ปรับขนาดรูปภาพให้ตรงกับขนาด container */
}

.timeLineItem {
  font-size: 12px;
  //   margin: 20px;
  //   margin-bottom: auto;
  .profile {
    display: flex;
    align-items: center;
  }
  .hearderTimeline{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    p{
      margin-top: 0;
      margin-bottom: 1em;
    }
    .divbtnOpenHistory{
      display: flex;
      align-items: flex-start;
      .btnOpen {
        font-size: 12px;
        width: 50px;
        height: 30px;
        border: 0;
        border-radius: 4px;
        padding: 4px;
        background: #1c87f2;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}

