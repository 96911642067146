

.modalOveride {
  // height: 500px;
  > div {
    border-radius: 10px !important;
    > div> div:last-child {
      display: none;
    }
  }
  > div> div > button {
    display: none;
  }
}

.modalOveride>div>div{
  padding: 0px !important;
}
.insidemodal{
  padding: 24px;
}
.insidemodalBtn{
  margin: 24px;
  margin-top: 0px;

}
.insidemodal1{
  padding: 24px !important;
  padding-top: 40px !important;
}
.closeBtn {
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.bottonBtn {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  width: 150px;
  height: 52px;
  border-radius: 5px;
  // background-color: #0f4c82;
  // color: #ffffff;
  // text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}
.bottonBtnNotActive {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  width: 150px;
  height: 52px;
  border-radius: 5px;
  // background-color: #ababab;
  // color: #717171;
  // text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}
.boxCreate {
  // margin: 15px;
  margin-top: 10px;
}
.boxCreateInput {
  width: 100%;
  font-size: 1.25rem;
  border-radius: 5px;
  border-width: 1px;
  padding: 10px;
  border-color: transparent;
  background-color: #fff;
  border: 1px solid #E7EDf0;
  box-sizing: border-box;
}

.switchOveride {
  background-color: rgba(0, 0, 0, 0.25) !important;
  > div:first-child {
    top: -1px !important;
    left: -1px !important;
    width: 23px !important;
    height: 23px !important;
  }
  > div::before {
    background-color: #ececec !important;
    border-radius: 50% !important;
  }
}

.switchOverideChecked {
  > div:first-child {
    top: -1px !important;
    // left: -1px !important;
    width: 23px !important;
    height: 23px !important;
  }
  > div::before {
    background-color: #ececec !important;
    border-radius: 50% !important;
  }
  background-color: #0f4c82 !important;
}

.directoryFiles {
  color: #93929c;
  font-size: 0.6875rem;
}
.itemFolders {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  border-radius: 5px;
  // padding: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 24px;
  // padding-right: 10px;
  border: 1px solid #E7EDf0;
}
.itemFolders:hover {
  background-color: #f7f7f7;
}
.textItem {
  font-size: 1rem;
  color: #36393A;
  margin-left: 20px;
}
.nextIcon {
  display: flex;
  background-color: #3875F6;
  border-radius: 5px;
  padding: 10px;
  width: 25px;
  float: right;
  text-align: center;
  justify-content: center;
}
.nextIconNotActive {
  display: flex;
  background-color: #a2a2a2;
  border-radius: 3px;
  padding: 8px;
  width: 25px;
  float: right;
  text-align: center;
}

.backIcon{
  cursor: pointer;
}

.symbolPath{
  margin-right: 5px;
  margin-left: 5px;
  color: #BEBEBE;
}
.boxPath{
  display: flex;
  font-size: 1.5625rem;
  margin-bottom: 45px;
}
.pathNotActive{
  color: #BEBEBE;
  cursor: pointer;
}
.pathActive{
  color: #000000;
}
.boxList {
  font-size: 1.125rem;
  margin-top: 10px;
  height: 310px;
  // overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 20px;
  box-sizing: border-box;
}


.textOverflowListFileText {
  // margin: auto;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  // font-size: 1rem;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.boxList::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.boxList::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.boxList:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}
