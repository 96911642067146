:global {
  .ant-collapse {
    .ant-collapse-expand-icon {
      color: #0052CC;
    }
    .ant-collapse-header-text {
      font-weight: 600;
    }
    .ant-collapse-item-active .ant-collapse-header-text {
        color: #0052CC;
      }
  }
}
