.accountHeader {
  position: fixed;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  z-index: 10;

  @media (min-width: 1024px) {
    padding: 1.5rem;
  }
}

.generateContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95vh;
  width: 100%;
}

.container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  // align-items: center;
  padding: 0 1rem;
  transition: all 0.3s ease;

  @media (min-width: 640px) {
    padding: 0 1.5rem;
  }

  &.collapsed {
    max-width: 100px;
    padding: 0 0.5rem;
    margin: 0;

    .header, .inputButtonContainer {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.collapseButton {
  position: fixed;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1677ff;
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #4096ff;
  }
}

.imgLogo {
  margin-bottom: 1rem;

  @media (min-width: 640px) {
    margin-bottom: 1.5rem;
  }
}

.logo {
  width: 3rem;
  height: 3rem;

  @media (min-width: 640px) {
    width: 60px;
    height: 60px;
  }
}

.label {
  text-align: center;
  font-size: 1.125rem;
  transition: opacity 300ms;
  padding: 0 0.5rem;
  margin-bottom: 1.5rem;

  @media (min-width: 640px) {
    font-size: 1.25rem;
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.875rem;
  }

  &.visible {
    opacity: 1;
  }

  strong {
    display: block;
    margin-top: 0.25rem;
  }
}

.buttonLabel {
  font-size: 0.875rem;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-bottom: 1.5rem;

  @media (min-width: 640px) {
    // margin-bottom: 2rem;
  }
}

.inputButtonContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media (min-width: 640px) {
    gap: 1.25rem;

    button {
      width: auto;
    }
  }

  :global(.ant-input) {
    font-size: 1rem;
    border-radius: 0.5rem;
    min-height: 120px;
    resize: none;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    &:focus {
      border-color: #3b82f6;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    }
  }
}
  .textArea {
    width: 100%;
    display: flex-grow;
  }

  .generateButton {
    margin-top: 0.5rem;
    height: 2.5rem;
    @media (min-width: 640px) {
      margin-top: 0;
    }
  }

  .generateButton:disabled {
    color: #fff;
    background-color: #9ca3af;
}

.generateWorkflowButton {
  background: linear-gradient(90deg, #6e41e2 0%, #8d65ff 100%);
  border: none;
  margin-top: 0.5rem;
  height: 2.5rem;

  &:hover {
    background: linear-gradient(90deg, #5d37c7 50%, #7d55ef 100%) !important;
  }

  &:active {
    background: linear-gradient(90deg, #4d2dab 0%, #6d45df 100%);
  }

  &:disabled {
    color: white;
    background-color: #9ca3af;

    &:hover {
      background-color: #9ca3af !important;
    }
  }
}

.welcomeButton {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #1677ff;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 300ms;
  cursor: pointer;
  border: 0;

  &:hover {
    background-color: #1677ff;
  }
}

.userMenu {
  min-width: 180px;
  border-radius: 0.5rem;
  overflow: hidden;

  .menuItems {
    padding: 0.5rem;

    .menuItem {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      padding: 0.75rem 1rem;
      border-radius: 0.375rem;
      cursor: pointer;
      transition: all 200ms;
      color: #778ca2;

      &:hover {
        background-color: #f0f8ff;
        color: #113f8c;
        transform: translateX(0.25rem);
      }
    }
  }
}

.privacy {
  a {
    // color: #f1f1f1 !important;
  }
}
