.container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px;
  position: absolute;
  bottom: 10px;
  right: 10px;

  @media (max-width: 768px) {
    gap: 16px;
  }
}

.micButton {
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
    color: #808080;
    transition: all 0.2s ease;
  }

  &.listening svg {
    color: #113F8C;
    animation: pulse 1.5s infinite;
  }

  &:hover {
    
    svg {
      color: #113f8c;
    }
  }

  @media (hover: none) {
    &:hover {
      transform: none;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }
  }
}

.languageButton {
  // background-color: #f8fafc;
  // border: 1px solid #e2e8f0;
  // border-radius: 50%;
  // padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  // min-width: 48px;
  // min-height: 48px;
  
  svg {
    color: #64748b;
    transition: color 0.2s ease;
  }

  &:hover {
    // background-color: #f1f5f9;
    // border-color: #cbd5e1;
    transform: translateY(-1px);
    
    svg {
      color: #3b82f6;
    }
  }

  &:active {
    transform: translateY(0);
  }

  @media (hover: none) {
    &:hover {
      transform: none;
    }
  }
}

.radioGroup {
  :global(.ant-radio-wrapper) {
    font-size: 16px;
    padding: 12px 16px;
    transition: all 0.2s ease;
    border-radius: 8px;
    margin-right: 0;
    width: 100%;

    &:hover {
      background-color: #f8fafc;
    }

    :global(.ant-radio-inner) {
      border-color: #3b82f6;
      
      &::after {
        background-color: #3b82f6;
      }
    }

    :global(.ant-radio-checked .ant-radio-inner) {
      border-color: #3b82f6;
      background-color: #fff;
    }

    @media (max-width: 768px) {
      padding: 16px;
      font-size: 18px;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
