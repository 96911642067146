.titleContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    .subTitleContainer {
        display: inline-flex;
        >p:first-child {
            color: #1C87F2;
        }
    }
}

.formContainer {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.formItem {
    margin-bottom: 15px;
}

.labelContainer {
    display: flex;
    align-items: center;
}

.requiredIndicator {
    margin-left: 5px;
    color: red;
}

.inputField {
    height: 40px;
}

.authenticationTitle {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 30px;
}

.passwordField {
    height: 40px;
    margin-bottom: 30px;
}

.cardDataSourceGrid {
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 20px;
}

.textContainer {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 10px;

    .text {
        font-size: 14px;
        margin: 0;
    }

    .textImportant {
        color: red;
    }
}

.buttonStyle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;

    >Button {
        width: 100%;
    }
}

.customScrollbar {
    height: 100%;
    overflow: auto;
    position: relative;
  }
  
  .customScrollbar::-webkit-scrollbar {
    display: none;
  }
  