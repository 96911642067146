
.itemuser {
  // display: inline-flex;
  align-items: center;
  
  width: 145px;
  border-radius: 5px;
  padding: 3px;
  margin: 3px;
  padding-left: 0px;
  padding-right: 30px;
}