.subscriptionPage {
  overflow-y: auto;
  max-height: 100vh;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(135deg, #2B3FF7, #2B7AF7);
    border-radius: 4px;
  }
}

.planHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 80px 0 60px;

  h1 {
    font-size: 52px;
    font-weight: 800;
    background: linear-gradient(135deg, #191D23 60%, #2B3FF7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    letter-spacing: -1px;
    line-height: 1.1;
  }

  .subtitle {
    font-size: 18px;
    color: #778CA2;
    font-weight: 400;
    max-width: 600px;
    text-align: center;
    line-height: 1.6;
  }

  .accent {
    width: 60px;
    height: 4px;
    background: linear-gradient(135deg, #2B3FF7, #2B7AF7);
    border-radius: 2px;
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    margin: 60px 0 40px;

    h1 {
      font-size: 36px;
    }

    .subtitle {
      font-size: 16px;
      padding: 0 24px;
    }
  }
}


.toggleCard {
  border: 2px solid #babddc85;
  border-radius: 12px;
  transition: all 0.3s ease;
  position: relative;
  border-radius: 12px;
  cursor: pointer;

  h4,
  h6 {
    color: #191d23;
    font-weight: 600;
  }

  span {
    color: #8d8d8d;
    font-weight: 300;
  }

  .metadata {
    font-weight: 400;
    color: #191D23;
  }

  button {
    font-weight: 600;
    padding: 14px 32px;
    border-radius: 16px;
    font-size: 16px;
    letter-spacing: 0.5px;
    transition: all 0.3s ease;
    cursor: pointer;
    background: transparent;
    color: #4361ee;
    border: 2px solid #4361ee;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 6px 20px rgba(67, 97, 238, 0.4);
      background: linear-gradient(135deg, #4895ef, #4361ee);
    }

    // Disabled state
    &:disabled {
      background: #e9ecef !important; // Add !important to override other states
      color: #000000 !important;
      border: 1px solid #dee2e6 !important;
      cursor: not-allowed;
      box-shadow: none !important;
      transform: none !important;

      &:hover {
        transform: none !important;
        box-shadow: none !important;
        background: #e9ecef !important;
      }
    }
  }

  &:hover {
    background: linear-gradient(135deg, #2B3FF7, #2B7AF7);
    transform: translateY(-4px);
    box-shadow:
      0 20px 40px rgba(43, 63, 247, 0.15),
      0 15px 25px rgba(37, 87, 214, 0.1);

    h4,
    h6 {
      color: #ffffff;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    span {
      color: rgba(255, 255, 255, 0.9);
    }

    .metadata {
      color: rgba(255, 255, 255, 0.95);
    }

    button {
      background: #ffffff;
      color: #2B3FF7;
      transform: translateY(-2px);
      box-shadow:
        0 10px 25px rgba(43, 63, 247, 0.2),
        0 8px 15px rgba(37, 87, 214, 0.15);
      cursor: pointer;
    }
  }

  &.selected {
    background: linear-gradient(135deg, #2B3FF7, #2B7AF7);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow:
      0 20px 40px rgba(43, 63, 247, 0.2),
      inset 0 1px 0 rgba(255, 255, 255, 0.1);
    transform: translateY(-3px);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    backdrop-filter: blur(10px);

    h4,
    h6 {
      color: #FFFFFF;
      font-weight: 700;
      letter-spacing: 0.5px;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    span {
      color: rgba(255, 255, 255, 0.85);
      font-weight: 500;
    }

    &:hover {
      background: linear-gradient(135deg, #2B3FF7, #3385FF);
      transform: translateY(-4px);
      box-shadow: 0 25px 50px rgba(43, 63, 247, 0.25);
    }

    button {
      background: #ffffff;
      color: #4361ee;
      border: 2px solid rgba(67, 97, 238, 0.2);
      box-shadow: 0 8px 25px rgba(67, 97, 238, 0.25);
      cursor: pointer;

      &:hover {
        background: #f8f9fa;
        transform: translateY(-2px);
        box-shadow: 0 10px 30px rgba(67, 97, 238, 0.3);
      }

      &:disabled {
        background: #ffffff !important;
        color: #49739e !important;
        border: 1px solid #dee2e6 !important;
        cursor: not-allowed;
        box-shadow: none !important;
        transform: none !important;

        &:hover {
          transform: none !important;
          box-shadow: none !important;
          background: #e9ecef !important;
        }
      }
    }
  }
}



.currentPlanLabel {
  position: absolute;
  top: 16px;
  right: 16px;
  background: linear-gradient(135deg, #00B4DB, #0083B0);
  color: #ffffff;
  padding: 6px 14px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  box-shadow:
    0 8px 20px rgba(0, 180, 219, 0.2),
    0 4px 12px rgba(0, 131, 176, 0.15);
  z-index: 2;
  transform: translateY(0);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);

  &:hover {
    transform: translateY(-1px);
    box-shadow:
      0 10px 22px rgba(0, 180, 219, 0.25),
      0 6px 14px rgba(0, 131, 176, 0.2);
  }
}

.currentPlanLabelFreeTrial {
  position: absolute !important;
  top: 16px !important;
  left: 16px !important;
  background: linear-gradient(135deg, #00B4DB, #0083B0);
  color: #ffffff;
  padding: 6px 14px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  box-shadow:
    0 8px 20px rgba(0, 180, 219, 0.2),
    0 4px 12px rgba(0, 131, 176, 0.15);
  z-index: 2;
  transform: translateY(0);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);

  &:hover {
    transform: translateY(-1px);
    box-shadow:
      0 10px 22px rgba(0, 180, 219, 0.25),
      0 6px 14px rgba(0, 131, 176, 0.2);
  }
}