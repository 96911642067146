.container {
  max-width: 100%;
  min-width: 500px;
  overflow: hidden;

  .innerContainer {
    position: relative;
    // min-width: 500px;
    display: flex;
    align-items: center;

    .textContainer {
      position: absolute;
      margin-left: 80px;
    }

    .textTitle {
      color: white;
      font-size: 22px;
      font-weight: 400;
      line-height: 33px;
      word-wrap: break-word;
    }

    .textSubTitle {
      color: white;
      font-size: 16px;
      font-weight: 300;
      line-height: 33px;
      word-wrap: break-word;
    }
  }

  .infoSection {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px #e9e9e9 solid;
    justify-content: space-between;
    align-items: center;
    display: inline-flex;

    .title {
      color: black;
      font-size: 16px;
      font-weight: 400;
      word-wrap: break-word;
    }

    .previewButton {
      background: #1c87f2;
      justify-content: center;
      align-items: center;
      display: flex;
      width: 70px;
      height: 27px;
      color: white;
      font-size: 10px;
      font-weight: 500;
      // line-height: 28px;
      word-wrap: break-word;

      &:disabled {
        color: #ababab;
        background: #f8f8f8;
        border: none;
      }
    }
  }
}

.tabActive {
  background-color: rgba(28, 135, 242, 0.15);
  // font-size: 10px !important;
  border-radius: 5px;
  padding-right: 16px;
  padding-left: 16px;
}

.tab {
  border-radius: 5px;
  padding-right: 16px;
  padding-left: 16px;
  color: #ababab;

  > div {
    color: #ababab !important;

    // font-size: 10px !important;
    :focus {
      color: #ababab !important;
    }
  }
}

.tabBarContainer {
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  display: inline-flex;
}

.searchInput {
  font-size: 12px;
  font-weight: 400;
}

.dropdownContainer {
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  display: flex;
}

.dropdownContainerStyle {
  display: inline-flex;
  justify-content: space-between;
  gap: 10px;
}

.dropdownItemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.dropdownText,
.labelText {
  color: #272727;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  width: 45px;
}

.labelText {
  overflow: hidden;
  width: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.iconContainer {
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  display: flex;
}

.iconWrapper {
  cursor: pointer;
}

.iconGroupContainer {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

.customScrollbar {
  height: 100%;
  overflow: auto;
  position: relative;
}

.customScrollbar::-webkit-scrollbar {
  display: none;
}

.tabBarStyle {
  min-width: 500px;
  height: 100%;

  .customTabBar {
    background: none;
  }

  > div {
    &::before {
      border: none !important;
    }
  }
}
