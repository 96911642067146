.controlTabContainer {
  border: #bebebe solid 1px;
  background: #fff;

  .tabHeader {
    width: 40vw;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 5px;

    .tabHeaderBtn {
      min-width: 100px;
      max-width: 120px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 10px;
      padding: 5px;
      border-radius: 4px 4px 0 0;
      background: #e8f4ff;
      color: #1c87f2;
    }
    .tabHeaderActiveBtn {
      min-width: 100px;
      max-width: 120px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 10px;
      padding: 5px;
      border-radius: 4px 4px 0 0;
      background: #1c87f2;
      color: #fff;
    }
    .tabHeaderAddBtn {
      width: 100px;
    }
  }

  .breadcrumpHeader {
    width: 40vw;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 5px;

    .stepNameWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .textBc {
        font-size: 12px;
        color: #000;
      }
    }
    .stepIconWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
    }
    .bcHeaderBtn {
      min-width: 140px;
      max-width: 160px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      gap: 5px;
      padding: 10px;
      border-radius: 4px 4px 0 0;
      // background: #e8f4ff;
      color: #1c87f2;
    }
    .bcHeaderActiveBtn {
      // min-width: 100px;
      // max-width: 120px;
      // height: 60px;
      // display: flex;
      // justify-content: space-evenly;
      // align-items: center;
      // gap: 10px;
      // padding: 5px;
      // border-radius: 4px 4px 0 0;

      // background: #1c87f2;
      // color: #fff;
      background: #e8f4ff;
      color: #1c87f2;
    }

    .bcHeaderAddBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 60px;
      font-size: 14px;
    }
  }

  .table {
    // height: 100%;
    // width: auto;
    min-width: 40vw;
    // border-collapse: collapse;
    border-spacing: 0.5px;

    .tableTr {
      background: #fff;
      .tableTd {
        width: 150px;
        height: 50px;
        border: #bebebe solid 1px;
        cursor: pointer;
        padding: 10px;
        z-index: 0;

        .insideTableTdFocus {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: #1c87f2 solid 1px;
          border-radius: 6px;
          background: #fff;
        }
        .insideTableTd {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: #bebebe solid 1px;
          z-index: 1;
          border-radius: 6px;
          background: #fff;
        }
        .insideTableTd:hover {
          border: #1c87f2 solid 1.5px;
        }

        .controlComponentCellFocus {
          width: auto;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          border: #1c87f2 solid 1px;
          border-radius: 6px;
          background: #fff;
        }
        .controlComponentCell {
          width: auto;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          // border: #bebebe solid 1px;
          z-index: 1;
          border-radius: 6px;
          background: #fff;
        }
        .controlComponentCell:hover {
          // border: #1c87f2 solid 1.5px;
        }
      }
      .tableTd:hover {
        border: #1c87f2 solid 1.5px;
      }
      .tableTdDragOver {
        background: rgba(28, 135, 242, 0.5) !important;
      }
      .tableTdFocus {
        border: #1c87f2 solid 1px;
        background: #e8f4ff !important;
      }
    }
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;

  .inputEditWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.textContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;

  h5 {
    font-size: 16px;
    width: 20ch;
    overflow-y: scroll;
    max-height: 10ch;
    word-wrap: break-word;
  }

  .controlTextEditWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 5px;

    .inputRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}

.controlImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: #bebebe solid 1px;
  background: #fff;
  padding: 25px;
}
