.searchBottonBtn {
    /* padding: 12px 23px 12px 23px;
    text-align: center; */
    font-size: 1.4375rem;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: #3875F6;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   display: flex;
   justify-content: center;
   align-items: center;
  }
  .tooltipcontent{
    width: 130px;
    align-self: center;
  }
  .tooltipinner {
    // text-align-last: center;
    margin-right: 15px;
    min-width: 30px;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    word-wrap: break-word;
    background-color: #434344;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.tooltiparrowcontent{
    position: absolute;
    top: 0px;
    right: -38px;
    bottom: 0;
    left: 0;
    display: block;
    width: 5px;
    height: 5px;
    margin: auto;
    background-color: #434344;
    content: '';
    pointer-events: auto;
    transform: rotateZ(45deg);
}

.anttooltiparrow {
    position: absolute;
    display: block;
    width: 13.07106781px;
    height: 13.07106781px;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}