.modalOveride {
  // height: 500px;
  width: 65% !important;
  // min-width: 800px !important;
  
}
.modalOveride {
  // height: 500px;
  > div {
    border-radius: 10px !important;
    > div> div:last-child {
      display: none;
    }
  }
  > div> div > button {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .modalOveride {
    min-width: 95% !important;
  }
}

@media only screen and (min-width: 769px) {
  .modalOveride {
    min-width: 800px !important;
  }
}

.modalOverideNotSelect {
  // height: 500px;
  // width: 80% !important;
  // min-width: 500px !important;
  > div {
    border-radius: 10px !important;
    > div:last-child {
      display: none;
    }
  }
  > div > button {
    display: none;
  }
}
.closeBtn {
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.bottonBtn {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  width: 150px;
  height: 52px;
  border-radius: 5px;
  background-color: #0f4c82;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}
.bottonBtnNotActive {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  width: 150px;
  height: 52px;
  border-radius: 5px;
  background-color: #ababab;
  color: #717171;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}
.boxCreate {
  margin: 15px;
  margin-top: 10px;
}
.boxCreateInput {
  width: 100%;
  font-size: 1.25rem;
  border-radius: 5px;
  border-width: 1px;
  padding: 10px;
  border-color: transparent;
  background-color: #f0f0f0;
}

.switchOveride {
  background-color: rgba(0, 0, 0, 0.25) !important;
  > div:first-child {
    top: -1px !important;
    left: -1px !important;
    width: 23px !important;
    height: 23px !important;
  }
  > div::before {
    background-color: #ececec !important;
    border-radius: 50% !important;
  }
}

.switchOverideChecked {
  > div:first-child {
    top: -1px !important;
    // left: -1px !important;
    width: 23px !important;
    height: 23px !important;
  }
  > div::before {
    background-color: #ececec !important;
    border-radius: 50% !important;
  }
  background-color: #0f4c82 !important;
}

.directoryFiles {
  color: #93929c;
  font-size: 0.6875rem;
}
.itemgroup {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  padding: 3px;
  margin: 3px;
  padding-left: 0px;
  padding-right: 0px;
}
.itemFolders:hover {
  background-color: #f7f7f7;
}
.textItem {
  font-size: 1.1rem;
  color: #000000;
  margin-left: 20px;
}
.nextIcon {
  display: block;
  background-color: #0f4c82;
  border-radius: 3px;
  padding: 8px;
  width: 25px;
  float: right;
  text-align: center;
}
.nextIconNotActive {
  display: block;
  background-color: #a2a2a2;
  border-radius: 3px;
  padding: 8px;
  width: 25px;
  float: right;
  text-align: center;
}

.backIcon {
  cursor: pointer;
}

.symbolPath {
  margin-right: 5px;
  margin-left: 5px;
  color: #bebebe;
}
.boxPath {
  display: flex;
  font-size: 1.5625rem;
  margin-bottom: 45px;
}
.pathNotActive {
  color: #bebebe;
  cursor: pointer;
}
.pathActive {
  color: #000000;
}
.boxList {
}
.boxListUserGroup {
  font-size: 1rem;
  margin-top: 10px;
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.textOverflowListFileText {
  // margin: auto;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  // font-size: 1rem;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.loadmore {
  margin: auto;
  // text-align: center;
}
.loadmoreBtn {
  background-color: #0f4c82;
  width: 170px;
  padding: 8px;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  text-align: center;
  margin: auto;
  cursor: pointer;
}

.itemuser {
  // display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 55px;
  border-radius: 5px;
  padding: 3px;
  margin: 8px;
  padding-left: 0px;
  padding-right: 30px;
}
.boxListUser {
  // width: 60px;
  // display: inline-flex;
  text-align: -webkit-center;
  padding-bottom: 10px;
  padding-right: 10px;
}

.textItemUser {
  font-size: 1.1rem;
  color: #000000;
  /* margin-left: 20px; */
}
.selectedItemShareUser {
  width: 27px;
  height: 27px;
  background-color: white;
  position: absolute;
  right: -35px;
  border-radius: 100%;
}
.selectedItemShareGroup {
  width: 27px;
  height: 27px;
  background-color: white;
  position: absolute;
  right: -7px;
  border-radius: 100%;
  top: -7px;
}
.selectedItemShareBox {
  position: relative;
}
.boxTextAlert {
  margin: 15px;
  margin-top: 60px;
}
.boxListWhoAccess {
  font-size: 1rem;
  margin-top: 10px;
  height: 288px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 10px;
}
.boxListWhoAccesses {
  font-size: 1rem;
  margin-top: 10px;
  height: 295px;
  overflow-y: hidden;
  overflow-x: hidden;
  border-top: 1px solid #b9b9b9;
  border-bottom: 1px solid #b9b9b9;
}
.headerDetail {
  width: 50%;
  color: #000000;
  font-weight: bold;
}
.valueDetail {
  width: 50%;
  color: #757575;
}
.iconuser {
  align-self: center;
}
.rowwhohasaccess {
  padding-top: 8px;
  font-size: 0.9rem;
}
.linetop {
  border-top: 1px solid #b9b9b9;
  margin-top: 20px;
}
.linebottom {
  border-top: 1px solid #b9b9b9;
  margin-top: 20px;
}

.detailBox {
  font-size: 0.75rem;
  margin-top: 10px;
}
.borderTop {
  border-top: 1px solid rgb(185, 185, 185);
}
.activityBox {
  font-size: 0.75rem;
  // margin-top: 10px;
  width: 100%;
}
.imgWorldBox {
  align-self: center;
  width: 54px;
  height: 54px;
  background-color: rgb(223, 236, 255);
  border-radius: 50%;
  text-align-last: center;
  text-align: center;
}
.imgWorld {
  width: 35px;
  height: 35px;
  position: relative;
  top: 20%;
}
.headerDetail {
  width: 50%;
  color: #000000;
  font-weight: bold;
}
.valueDetail {
  width: 50%;
  color: #757575;
}
.btnChange {
  color: #428dff;
  border: 1px solid #428dff;
  border-radius: 100px;
  padding: 6px 14px;
  background-color: white;
  width: 100px;
}

.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    // display: flex;
    align-items: baseline;
  }
}

.boxLink {
  width: 95%;
  color: #428dff;
  background-color: #f7f7f7;
  border-radius: 100px;
  padding: 8px;
  font-size: 1rem;
  border: none;
}

.iconheader {
  text-align: center;
  display: block;
  margin-left: 25px;
  cursor: pointer;
}

.iconheaderTab {
  text-align: center;
  display: block;
  margin-right: 10px;
  cursor: pointer;
}
.imgWorldBoxSheet {
  align-self: center;
  width: 25px;
  height: 25px;
  background-color: black;
  border-radius: 50%;
  text-align-last: center;
  text-align: center;
}

.selectPermission2 {
  color: white;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  background-color: #0f4c82;
  border: none;
  margin-left: -4px;
  // border: 1px solid #717171;
  font-size: 1rem;
}
.selectPermission1 {
  color: white;
  // border: 1px solid #428DFF;
  border-radius: 5px;
  padding: 9px 9px 9px 12px;
  background-color: #0f4c82;
  border: none;
  text-align-last: center;
  // border: 1px solid white;
  font-size: 1rem;
}
.boxPermision {
  display: flex;
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  padding: 5px;
  padding-right: 5px;
  background-color: #0f4c82;
  border-radius: 10px;
}
.btnCopy {
  color: white;
  border: 1px #428dff;
  border-radius: 100px;
  padding: 8px 14px;
  background-color: #428dff;
  width: 100px;
  min-height: 36px;
}

.boxListWhoAccess::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.boxListWhoAccess::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.boxListWhoAccess:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.boxListWhoAccesses::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.boxListWhoAccesses::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.boxListWhoAccesses:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.boxListUser::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.boxListUser::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.boxListUser:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.boxListUserGroup::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.boxListUserGroup::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.boxListUserGroup:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.boxUsernametoEdit {
  margin: auto;
  margin-left: 8px;
}

@media only screen and (min-width: 500px) {
  .column21 {
    width: 87.5%;
  }
  .column3 {
    width: 12.5%;
  }
  .valueDetailEdit {
    width: 100% !important;
  }
}

@media only screen and (max-width: 499px) {
  .column21 {
    width: 100%;
  }
  .column3 {
    width: 100%;
    > div {
      float: unset !important;
      text-align: center;
      margin-top: 10px;
    }
  }
  .valueDetailEdit {
    width: calc(100% - 55px) !important;
  }
  .boxLink{
    width: 100% !important;
  }
}
