.modalContentWrapper {
  .modalTitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .modalContent {
    .inputFieldContainer {
      margin-bottom: 15px;
      .textTopic {
        display: flex;
        gap: 5px;
        .text {
          font-size: 15px;
        }
        .textImportant {
          font-size: 18px;
          color: #d92d20;
        }
      }
      .inputTabName {
        
        .searchInput {
            height: 40px;
            margin-bottom: 15px;
        }
      }
    }
  }
}

.modalFooter {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    .cancelBtn {
      height: 100%;
      width: 48%;
      border-radius: 8px;
      padding: 0 8px;
    }
    .confirmBtn {
      height: 100%;
      width: 48%;
      background: #1C87F2;
      color: #ffffff;
      border-radius: 8px;
    }
    .confirmBtnDisabled {
        height: 100%;
        width: 48%;
        background: #E9E9E9;
        color: #ffffff;
        border-radius: 8px;
      }
    .confirmBtn:hover {
      background-color: #1C87F2;
    }
  }
