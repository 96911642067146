.modalTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 24px;
}
.modalFooter {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .addBtn {
    height: 100%;
    width: 20%;
    background: #1c87f2;
    color: #ffffff;
    border-radius: 8px;
  }
  .cancelBtn {
    height: 100%;
    width: 50%;
    border-radius: 8px;
    padding: 0 8px;
  }
  .confirmBtn {
    height: 100%;
    width: 50%;
    background: #1c87f2;
    color: #ffffff;
    border-radius: 8px;
  }
  .confirmBtn:hover {
    background-color: #1c87f2;
  }
}
.modalContent {
  text-align: center;
  margin-bottom: 20px;

  .selectWrapper {
    // height: 300px;
    margin-top: 20px;
    .titleWrapper {
      display: flex;
      align-items: center;

      .textHeader {
        font-size: 12px;
        margin: 0;
      }
      .textImportant {
        color: red;
        font-size: 14px;
      }
    }
    .actionWrapper {
      display: flex;
      align-items: center;
      gap: 6px;

      .inputField {
        width: 100%;
        height: 40px;
      }

      .genTableSelect {
        font-size: 12px;
        height: 40px;
        text-align: start;
      }
      .actionIconWrapper {
        height: 28px;
        width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 6px;
        border: solid 1px #e9e9e9;

        .actionIcon {
          fill: #ababab;
        }
      }
      .actionIconWrapper:hover {
        border-color: #1c87f2;

        .actionIcon {
          fill: #1c87f2;
        }
      }
    }
    .inputFieldContainer {
      min-height: 30vh;
      max-height: 50vh;
      overflow-y: auto;
      scrollbar-width: none;
      transition: all 0.5s ease;
      padding-bottom: 5px;
    }
  }
}

.createBoInputOneColWrapper {
  display: flex;
  flex-direction: column;
}
.createBoInputMoreOneColWrapper {
  display: grid;
  grid-template-columns: repeat(3, minmax(220px, 1fr));
  gap: 10px;
}

.primaryKeyWrapper {
  display: flex;
  margin-left: 20px;
  gap: 10px;
  justify-content: space-between;
}
