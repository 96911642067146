.genMFLoadingOpen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex; 
  justify-content: center;
  align-items: center;

  $circle-size: 200px;
  $circle-color: #ccc;
  $wave-color: #4973ff;
  $text-color: white;

  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: $circle-size;
    height: $circle-size;
    background: $circle-color;
    // border: 5px solid #fff;
    // box-shadow: 0 0 0 5px $wave-color;
    border-radius: 50%;
    overflow: hidden;
  }

  .wave {
    position: relative;
    width: 100%;
    height: 100%;
    background: $wave-color;
    border-radius: 50%;
    box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wave h2 {
    position: relative;
    z-index: 100;
    color: $text-color;
    font-size: 36px;
  }

  .wave::before,
  .wave::after {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .wave::before {
    border-radius: 40%;
    background: lightblue;
    animation: animate 50s forwards;
  }

  .wave::after {
    border-radius: 30%;
    background: rgba(255, 255, 255, 0.5);
    animation: animate 50s forwards;
  }

  @keyframes animate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
      transform: translate(-50%, -70%) rotate(270deg);
    }
    80%,
    90% {
      transform: translate(-50%, -80%) rotate(320deg);
    }
    100% {
      transform: translate(-88%, -88%) rotate(340deg);
    }
  }
}
.genMFLoadingHide {
  display: none;
}
