.container {
    padding: 24px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .userCard {
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .userCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  }
  
  .userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 16px;
  }
  
  .avatar {
    margin-bottom: 16px;
    border: 4px solid #f0f2f5;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .userDetails {
    margin-bottom: 24px;
  }
  
  .userName {
    margin-bottom: 4px !important;
  }
  
  .position {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    color: #1890ff;
  }
  
  .actions {
    margin-top: 16px;
    width: 100%;
    justify-content: center;
  }
  
  .actionButton {
    min-width: 120px;
    height: 40px;
    border-radius: 8px;
  }
  
  .statsCard {
    background: #fafafa;
    border-radius: 8px;
    border: none;
  }
  
  .statistic {
    text-align: center;
  }
  
  .statistic :global(.ant-statistic-title) {
    color: #8c8c8c;
    font-size: 14px;
  }
  
  .statistic :global(.ant-statistic-content) {
    color: #262626;
    font-size: 24px;
  }
  
  @media (max-width: 768px) {
    .userInfo {
      padding: 8px;
    }
  
    .actionButton {
      min-width: 100px;
    }
  }
  .userListContainer {
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .user-card {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .user-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
  }
  
  .user-card .ant-card-body {
    position: relative;
    z-index: 1;
  }
  
  .user-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(24, 144, 255, 0.05),
      rgba(82, 196, 26, 0.05)
    );
    border-radius: 16px;
    z-index: 0;
  }


  // /////////////////////////////////////////////////////////////////

  .parent{
    padding: 18px;
    height:400px;
    display:grid;
    grid-template-columns:repeat(4, auto);
    gap:5px;
    border: 5px black solid;
    border-radius:5px;
  }
  
  .parent div{
    background: gray;
    text-align:center;
      border: 3px black solid;
    border-radius:5px
  }
  
  .parent .item1{
    grid-column: 2/5;
  }
  
  .parent .item2{
    grid-row: 1/5
  }
  .parent .item7{
    grid-column: 1/5;
  }
  
  .parent .item6{
    grid-column: 2/5;
    grid-row: 3/5;
  }

  .tagBtnActive {
    background-color: #1C87F2;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;
    border: none;
  }
  
  .tagBtn {
    background-color: rgba(28, 135, 242, 0.15);
    color: #1C87F2;
    padding: 0.25rem 0.75rem;
    border-radius: 1.5rem;
    border: none;
    cursor: pointer;
  }
  