$text-color: #121927;
$text-title-color: #262626;
$text-body-color: #8d8d8d;
$text-body-color-2: #414141;
$text-body-color-3: #393939;
$primary: #1c87f2;

.pageWrapper {
  height: 100vh;
  overflow-y: auto;
}

.contentContainer {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

:global {
  html, body {
    height: 100%;
    margin: 0;
    overflow: hidden;
  }
}

.headerBreadcrumb {
  color: #000 !important;
  font-weight: 700 !important;
}

.header {
  color: $text-color !important;
  font-size: 30px !important;
  font-weight: 700 !important;
}

.cardContainer {
  border-radius: 12px !important;
  padding: 18px 12px;

  h6 {
    font-size: 16.75px;
    font-weight: 600;
    color: $text-title-color;
  }

  .desc {
    color: $text-body-color;
    font-size: 14px;
    font-weight: 400;
  }
}

.paymentForm {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  all: unset;
}

.labelSelect {
  font-size: 12px !important;
  font-weight: 400;
  color: $text-title-color;
  padding-bottom: 4px;
}

.summaryPackage {
  font-weight: 500;
  color: $primary;
}

.summaryDetail {
  font-weight: 500;
  color: $text-body-color-2;
}

.summaryTotal {
  font-size: 20px;
  font-weight: 600;
  color: $text-body-color-3;
}

.container {
  height: 100%;
  border-radius: 16px;
  background-color: #ffffff;
  padding-top: 18px;
}