.closeBtn {
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  top: 0px;
  right: -20px;
  position: absolute;
}

.boxdetail {
  // width: 380px;
  width: 350px;
  min-height: 100vh;
  background-color: white;
  position: fixed;
  // right: 0px;
  right: 10px;
  padding-top: 10px;
  /* bottom: 0px; */
  // min-height: 62px;
  // border-left: #e7e6e6 solid 2px;
  top: 25px;
  border-radius: 20px;
  border: 1px solid #E7EDF0;
}
.boxdetail::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.boxdetail::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.boxdetail:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.textOverflowListFileText {
  // margin: auto;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  // font-size: 1rem;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.headerBoxDetail {
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
  // border-bottom: 1px #bebebe solid;
  // border-top: 1px #bebebe solid;
  align-self: center;
  align-content: center;
}
.lineBoxTopBottom {
  border-bottom: 1px #E7EDF0 solid;
  border-top: 1px #E7EDF0 solid;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-top: 30px;
}

.boxIconText {
  margin: auto;
  margin-left: 5px;
}
.rowDetail {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.textHead {
  font-weight: 600;
  color: #36393A;
}

.textHead + div{
  color: #36393A
}
.itemuser {
  // display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 145px;
  border-radius: 5px;
  padding: 3px;
  margin: 3px;
  padding-left: 0px;
  padding-right: 30px;
}
.verticalCenter {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.line {
  border-left: 1px solid #E7EDF0;
  margin-left: 15px;
  margin-right: 15px;
}
.classBoxOveride {
  width: 40px !important;
  height: 40px !important;
}

.linkShareBackground {
  text-align: -webkit-center;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  width: 44px;
  height: 44px;
  background-position: center;
}
.linkShare {
  color: #000000;
  align-self: center;
  width: 44px;
  height: 44px;
  background-color: #f4f4f4;
  border-radius: 50%;
}

@media only screen and (max-width: 768px) {
  .closeBtn {
    right: -5px;
  }
  .boxdetail {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  /* For tablets: */
  .closeBtn {
    right: -5px;
  }

  .boxdetail {
    width: 350px;
  }
}
