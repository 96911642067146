.customInfinitscroll {
  overflow: visible !important;
}

.customInfinitscroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

.customInfinitscroll::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

.customInfinitscroll:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}
.textOverflowListFileText {
  // margin: auto;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: 0.9rem;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.boxtext{
  padding: 2px;
}
.textEmpty{
  text-align: center;
  color: #a0a0a0;
  font-size: 0.8rem;
}