.dashboard-container {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: rgb(249 250 251);

  @media screen and (max-width: 640px) {
    height: calc(100vh - 4rem);
    overflow-y: auto;
  }

  @media screen and (max-width: 768px) {
    height: calc(100vh - 4.25rem);
    overflow-y: auto;
  }
}

.dashboard-header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 2rem;
  border-bottom: 1px solid #dcdcdc;
}

.dashboard-title {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  width: 50%;
}

.new-project-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.new-project-text {
  font-weight: 500;
  font-size: 0.875rem;
}

.dashboard-content {
  padding: 2rem;
  gap: 2rem;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 8vh;
}

@media (min-width: 768px) {
  .dashboard-content {
    margin-bottom: auto;
  }
}

.section-title {
  font-weight: 600;
  font-size: 1rem;
  color: #121927;
  margin-bottom: 1rem;
}

.cards-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 768px) {
  .cards-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.card {
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
}

.card-subtitle {
  color: #6b7280;
  font-size: 0.875rem;
}

.agent-card {
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.agent-status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.agent-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.agent-name {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.last-interaction {
  color: #6b7280;
  font-size: 0.875rem;
}

/* Total balance section specific styles */
.grid-cols-1 {
  display: grid;
  gap: 1rem;
}

.xl\:grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

@media (min-width: 1280px) {
  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }
}

@media (min-width: 768px) {
  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.space-y-4 {
  row-gap: 1rem;
}

.space-y-6 {
  row-gap: 1.5rem;
}
