
.driver-popover.driverjs-theme button {
    border-radius: 5px;
  }

  .driver-popover.driverjs-theme .driver-popover-next-btn {
    background-color: #1677ff;
    color: #ffffff;
    text-shadow: none;
  }
  