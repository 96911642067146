.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.iframeContainer {
  width: 100%;
  height: 800px; // หรือกำหนดขนาดตามต้องการ
  margin-bottom: 20px; // เว้นระยะห่างจากแกลเลอรี
}

.gallery {
  display: grid;
  /* ใช้ grid layout */
  grid-template-columns: repeat(5, 1fr);
  /* แถวละ 5 คอลัมน์ */
  gap: 10px;
  /* ระยะห่างระหว่างการ์ด */
  padding: 10px;
  /* เพิ่ม padding รอบๆ */
  // overflow: auto;
  height: 100%;
  overflow: auto;
  position: relative;
}

//ScrollBar

.gallery::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 3px;
}

.gallery::-webkit-scrollbar {
  background: transparent;
  height: 6px;
}

.gallery:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.galleryImage {
  flex: 0 0 auto; // ทำให้ภาพไม่ยืด
  width: 250px; // ความกว้างของแต่ละภาพ
  height: 250px; // ความสูงของแต่ละภาพ
  margin-right: 10px; // ระยะห่างระหว่างภาพ
  object-fit: cover; // รักษาสัดส่วนของภาพ
  border-radius: 5px; // มุมกลมของภาพ
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); // เงาให้ภาพ
}



.card {
  border: 1px solid #ddd;
  /* ขอบการ์ด */
  border-radius: 8px;
  /* มุมโค้ง */
  // overflow: hidden; /* ซ่อนส่วนที่เกิน */
  width: 220px;
  height: 300px;
  // text-align: center; /* จัดข้อความกลาง */
  gap: 20px;
  padding: 15px 15px 0px 15px;
  margin: 5px;
}

.cardGallery {
  border: 1px solid #ddd;
  border-radius: 8px;

  width: 220px;
  height: 300px;
  gap: 20px;
  padding: 15px 15px 0px 15px;
  margin: 5px;

  &:hover {
    background-color: aliceblue;
    border-color: aliceblue;
  }
}

.cardTitle {
  padding: 8px;
  font-weight: bold;

}

.titleName {
  display: flex;
  align-items: center;

}

.titleName div {
  flex-shrink: 0;
  /* Prevent the "Page :" text from shrinking */
}

.titleName::after {
  content: ' ';
  /* Add a space between the "Page :" and name */
}

.fileName {
  font-size: 14px;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap
}

.Skeleton {
  width: 100%;

  >div>h3 {
    width: 100% !important;
  }
}