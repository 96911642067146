.ant-select-selector {
  border: 1px solid #e5e7eb !important;
  border-radius: 8px !important;
  min-height: 40px !important;
  padding: 4px 12px !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #2970ff !important;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active {
  border-color: #2970ff !important;
  box-shadow: 0 0 0 2px rgba(41, 112, 255, 0.1) !important;
}

.ant-select-selection-placeholder {
  color: #9ca3af !important;
}

.ant-select-dropdown {
  padding: 4px !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

.ant-select-item {
  border-radius: 6px !important;
  padding: 8px 12px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #eef4ff !important;
  color: #2970ff !important;
}

.ant-select-multiple .ant-select-selection-item {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 4px !important;
}
