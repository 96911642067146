.modalOveride {
  // height: 500px;
  > div {
    border-radius: 10px !important;
    > div> div:last-child {
      display: none;
    }
  }
  > div> div > button {
    display: none;
  }
}
.closeBtn {
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.bottonBtn {
  /* padding: 12px 23px 12px 23px;
    text-align: center; */
  width: 150px;
  height: 52px;
  border-radius: 5px;
  background-color: #0f4c82;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  border-color: unset !important;
  border-width: unset !important;
  border-style: unset !important;
}
.boxCreate {
  margin: 15px;
  margin-top: 10px;
}
.boxCreateInput {
  width: 100%;
  font-size: 1.25rem;
  border-radius: 8px;
  border-width: 1px;
  padding: 10px;
  border-color: transparent;
  border: 1px solid #E7EDF0;
  background-color: #fff;
}

.switchOveride {
  background-color: rgba(0, 0, 0, 0.25) !important;
  > div:first-child {
    top: -1px !important;
    left: -1px !important;
    width: 23px !important;
    height: 23px !important;
  }
  > div::before {
    background-color: #ececec !important;
    border-radius: 50%  !important;
  }
  
}

.switchOverideChecked {
  > div:first-child {
    top: -1px !important;
    // left: -1px !important;
    width: 23px !important;
    height: 23px !important;
  }
  > div::before {
    background-color: #ececec !important;
    border-radius: 50%  !important;
  }
  background-color: #0f4c82 !important;
}

.directoryFiles {
  color: #93929c;
  font-size: 0.6875rem;
}


