table#BoObjectConfigTb > tbody {
  display: block;
  /* height: 100%; */
  overflow: auto;
  scrollbar-width: none;
  width: 100%;
  table-layout: fixed;
}
table#BoObjectConfigTb thead,
table#BoObjectConfigTb tbody tr{
  display: table;
  width: 100%;
  table-layout: fixed;
}
table#BoObjectConfigTb td {
  text-align: center;
  font-size: 12px;
}
table#BoObjectConfigTb > tbody > tr {
  height: 60px;
  border-bottom: solid 1px #e9e9e9;
}
table#BoObjectConfigTb thead{
  width: calc(100% - 1em);
  border-bottom: solid 1px #e9e9e9;
}
table#BoObjectConfigTb th {
  height: 50px;
}
table#BoObjectConfigTb {
  width: 100%;
}


/*MORE SETTING */
table#MoreSettingTb > tbody {
  display: block;
  height: 35vh;
  overflow: auto;
  scrollbar-width: none;
  width: 100%;
  table-layout: fixed;
}
table#MoreSettingTb thead,
table#MoreSettingTb tbody tr{
  display: table;
  width: 100%;
  table-layout: fixed;
}
table#MoreSettingTb td {
  text-align: start;
  font-size: 12px;
  padding-left: 5px;
}
table#MoreSettingTb > tbody > tr {
  height: 60px;
  border-bottom: solid 1px #e9e9e9;
}
table#MoreSettingTb thead{
  width: calc(100% - 1em);
  border-bottom: solid 1px #e9e9e9;
}
table#MoreSettingTb th {
  height: 50px;
  text-align: start;
  padding-left: 5px;
}
table#MoreSettingTb {
  width: 100%;
}