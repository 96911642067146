.contentModal {
  width: 100%;
  height: 100%;
  margin-top: 30px;
}

.container {
    display: grid;
    padding: 10px;
    border-bottom: solid 1px #e9e9e9;
    grid-template-columns: 250px 250px 100px 150px auto; /* ให้แต่ละคอลัมน์มีขนาดที่ไม่เท่ากัน */
    grid-gap: 20px; /* กำหนดระยะห่างระหว่างองค์ประกอบ */
}

.iconAdd {
    width: 100%; 
    height: 100%;
    color:#1C87F2;
}

.containerSelectFormTable {
  margin-top: 30px;
}

.footerContainer {
  margin-top: 50px;
  width: 30%;
  display: flex;
  justify-content: space-evenly;
  .btnCancel {
      width: 40%;
      border: 0;
      border-radius: 4px;
      padding: 4px 0;
      cursor: pointer;
  }
  .btnCreate {
      width: 40%;
      border: 0;
      border-radius: 4px;
      padding: 4px 0;
      background: #1C87F2;
      color: #FFFFFF;
      cursor: pointer;
  }

    .btnCreate:disabled {
      background-color: #ABABAB; /* กำหนดสีพื้นหลังเมื่อปุ่มถูกปิดใช้งาน */
      color: black; /* กำหนดสีข้อความเมื่อปุ่มถูกปิดใช้งาน */
      cursor: not-allowed;
    }
}
