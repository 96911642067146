table#DropdownDataListTb > tbody {
    display: block;
    height: 30vh;
    overflow: auto;
    scrollbar-width: none;
    width: 100%;
    table-layout: fixed;
  }
  table#DropdownDataListTb thead,
  table#DropdownDataListTb tbody tr{
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  table#DropdownDataListTb td {
    text-align: start;
    font-size: 14px;
    padding-left: 5px;
  }
  table#DropdownDataListTb > tbody > tr {
    height: 60px;
    border-bottom: solid 1px #e9e9e9;
  }
  table#DropdownDataListTb > tbody > tr:first-child {
    position: sticky;
    top: 0;
    background-color: #ffffff; 
    z-index: 100;
  }
  table#DropdownDataListTb thead{
    width: calc(100% - 1em);
    border-bottom: solid 1px #e9e9e9;
    background: #f8f8f8;
  }
  table#DropdownDataListTb th {
    height: 50px;
    text-align: start;
    padding-left: 5px;
    font-weight: 500;
    font-size: 16px;
  }
  table#DropdownDataListTb {
    width: 100%;
  }