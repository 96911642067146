.buttonModal{
    width: 150px;
    height: 48px;
}
.buttonGroup{
    display: flex;
    justify-content: center;
    gap: 30px;
}
.titleText{
    font-size: 18px;
    font-weight: 600;
}

.modalContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}