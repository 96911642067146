.customInfinitscroll {
  // overflow: visible !important;
}
.customInfinitscroll::-webkit-scrollbar {
  display: none;
}

.customInfinitscroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

// .customInfinitscroll::-webkit-scrollbar {
//   background: transparent;
//   width: 10px;
// }

.customInfinitscroll:hover {
  &::-webkit-scrollbar-thumb {
    background: #bbbfca;
  }
}

.textEmpty{
  text-align: center;
  color: #a0a0a0;
  font-size: 0.8rem;
}
.customInfinitscroll{
  min-height: 100vh;
}