.personalGroupList {
  width: 60px;
  height: 60px;
  border-radius: 40%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: rgb(158, 158, 158);
  border-color: rgb(158, 158, 158);
  border-style: solid;
  border-width: 1px;
}
.imgShareGroup {
  width: 40px;
  height: 40px;
  border-radius: 5%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: rgb(158, 158, 158);
  border-color: rgb(158, 158, 158);
  border-style: solid;
  border-width: 1px;
}
.imgShare {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: rgb(158, 158, 158);
  border-color: rgb(158, 158, 158);
  border-style: solid;
  border-width: 1px;
}

