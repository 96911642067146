.boFieldToolContainer {
  width: 35px;
  height: 35px;
  background: #fff;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 5px 5px 0px #00000008;
  box-shadow: 0px 1px 3px 0px #434c551a;
  box-shadow: 0px 2px 20px 0px #0000000d;
  cursor: pointer;
}
.boFieldToolContainer:hover {
    border: 1px solid #e6f4ff;
}

//CELL TOOL
.cellToolContainer {
  height: 35px;
  // width: 200px;
  // overflow-x: scroll;
  scrollbar-width: thin;
  background: #fff;

  display: flex;
  justify-content: space-evenly;
  // gap: 5px;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 5px 5px 0px #00000008;
  box-shadow: 0px 1px 3px 0px #434c551a;
  box-shadow: 0px 2px 20px 0px #0000000d;

  .actionWrapper {
    min-width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .lineDivide {
    height: 70%;
    border-right: 1px solid #e6f4ff;
  }
  .actionWrapperBtn {
    min-width: 40px;
    min-height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

}

