.editorContainer {
    position: relative;
    // width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #ccc;
    overflow: hidden;
}

.codeMirror {
    height: 100%;

    // height: calc(100% - 40px);
    >div {
        height: 100%;
    }
}

.copyButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ffffff;
    color: rgba(217, 217, 217, 1);
    border: none;
    border-radius: 5px;
    padding: 8px 8px;
    cursor: pointer;
    z-index: 10;
    opacity: 0.8;
    transition: opacity 0.3s, background-color 0.3s, color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.copyButton:hover {
    opacity: 1;
    background-color: #323538c9;
    color: #ffffff;
}

.chatContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    flex-direction: column;
}