.chatContainer {
  display: flex;
  justify-content: flex-start;
  // max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.profileImage {
  width: 40px;
  height: 40px;
  border-radius: 20%;
  margin-right: 10px;
}

.chatBubble {
  width: 100%;
  min-height: 100px;
  flex: 1 1;
  // border-radius: 15px;
  // border: 1px solid rgb(212 212 212);
  padding: 10px;
  // position: relative;
  display: inline-block;
  max-width: 100%;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  color: rgba(0, 0, 0, 1);

  .chatBubbleOther {
    display: flex;
    margin-bottom: 10px;
  }

  .chatBubbleMe {
    display: flex;
    margin-bottom: 10px;

    .messageHeader {
      font-weight: bold;
      padding-left: 10px;
    }

    .messageContent {
      .messageBody {
        overflow-x: auto;
        word-wrap: break-word;
        white-space: pre-wrap;

        p {
          background: #e9e9eb;
          border-radius: 12px;
          padding: 10px;
        }
      }
    }
  }
}

.chatBubble::after {
  // content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #d0e7ff;
  border-bottom: 0;
  margin-left: -10px;
  margin-bottom: -10px;
}

pre {
  margin: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.messageHeader {
  font-weight: bold;
  padding-left: 10px;
  // margin-bottom: 5px;
}

.messageBody {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  padding: 10px;
  max-width: 850px;
}

.messageContent {
  &:hover {
    background-color: #f7f7f7;
    border-radius: 8px;
    transition: opacity 0.3s ease;
  }
}

.buttonsContainer {
  // display: none;
  visibility: hidden;
  display: flex;
  margin-top: 10px;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  opacity: 0;
}

.visible {
  visibility: visible;
  display: flex;
  margin-top: 10px;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  transition: opacity 0.3s ease;
}
