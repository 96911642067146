table#SearchResultTb > tbody {
    display: block;
    height: 60vh;
    overflow: auto;
    scrollbar-width: none;
    width: 100%;
    table-layout: fixed;
  }
  table#SearchResultTb thead,
  table#SearchResultTb tbody tr{
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  table#SearchResultTb td {
    text-align: start;
    font-size: 14px;
    padding-left: 5px;
  }
  table#SearchResultTb > tbody > tr {
    height: 60px;
    border-bottom: solid 1px #e9e9e9;
  }
  table#SearchResultTb thead{
    width: calc(100% - 1em);
    border-bottom: solid 1px #e9e9e9;
    background: #f8f8f8;
  }
  table#SearchResultTb th {
    height: 50px;
    text-align: start;
    padding-left: 5px;
    font-weight: 500;
    font-size: 16px;
  }
  table#SearchResultTb {
    width: 100%;
  }