.boxIcon {
  background-color: #0f4c82;
}
.boxIconCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.boxIconWhite {
  background-color: white;
}
.boxIconCenterWhite {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
