.noDataContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
  }
  
  .noDataHeader {
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
  }
   
  .noDataText {
      text-align: center;
  }
  
  .noDataImg {
    width: 500px;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }